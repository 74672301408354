import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OrgService from '../../Services/orgService';
import { useSelector } from 'react-redux';
import LoadingScreen from '../Helpers/Components/LoadingScreen';

function AdvertisingMain() {
  const [campaigns, setCampaigns] = useState({})
  const navigate = useNavigate()
  const authState = useSelector((state) => state.auth)
  const orgService = new OrgService()

  useEffect(() => {
    const fetchCampaigns = async () => {
      const fetchedCampaigns = await orgService.fetchCampaigns(authState.selectedOrganization.id);
      setCampaigns(fetchedCampaigns);
    };
    fetchCampaigns();
}, [])

  return (
    <div className="billing-container">
      <h2>Advertising Campaigns</h2>
      <div className="dividerHorizontal"></div>
      {campaigns.length > 0 ? (
        <div className="campaign-list">
          {campaigns.map((campaign, index) => (
             <Link to={`/advertising/${campaign.id}`} key={campaign.id} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className='advertising-summary'>
                <div className='hStack' style={{justifyContent: "space-between"}}>
                <div>
                 <div className="advertising-option__title">{campaign.name}</div>
    
    <div className="advertising-option__price">R{campaign.pricePerBike}</div>
    <div className="advertising-option__month">per bike/month</div>
    </div>
    <div>
    <div className="advertising-option__title">{campaign.noOfBikes} Bikes</div>
    <div className="advertising-option__title" style={{color: campaign.status == "Active" ? "var(--accent-color)" : "Red"}}>{campaign.status}</div>
    </div>
    </div>
  </div>
  </Link>
          ))}
        </div>
      ) : (
        <div>
        <p className="friendly-message">You currently have no advertising campaigns underway</p>
        <p className="friendly-message">Please contact <a href="mailto:admin@shapshapbikes.co.za">admin@shapshapbikes.co.za</a> if you are interested in creating a campaign</p>
      </div>
      )}
    </div>
  );
}

export default AdvertisingMain;
