import React, { useState } from "react";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [confirmationResult, setConfirmationResult] = useState(null);

    return (
        <AuthContext.Provider value={{ confirmationResult, setConfirmationResult }}>
            {children}
        </AuthContext.Provider>
    );
};