import React, { useState } from "react";
import AuthService from "../../../Services/authService";
import { useDispatch, useSelector } from "react-redux";

const CreateUserProfile = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const state = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const authService = new AuthService();

    const handleSubmit = (event) => {
        event.preventDefault();
        authService.createProfile(state.uid, fullName, email, state.user.phoneNumber, dispatch)
    };

    return (
        <div className="text-form">
            <h1>Welcome to 321!</h1>
            <h2>Complete Your Profile</h2>
            <form onSubmit={handleSubmit}>
                <label className="text-form-label">
                    Full Name
                    <input type="text" value={fullName} onChange={(event) => setFullName(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Email
                    <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Phone Number
                    <input 
                        type="text" 
                        value={state.user.phoneNumber} 
                        disabled
                        className="disabled-input"
                    />
                </label>
                <input className={"primaryButton"} style={{marginTop: "40px"}} type="submit" value="Confirm" />
            </form>
        </div>
    );
}

export default CreateUserProfile;

