import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import FormSection from '../../Helpers/Components/FormSection';
import formatIsoDateTime from '../../Helpers/Functions/FormatIsoDateTime';
import RestaurantService from '../../Services/restaurantService';

function RestaurantDetailView() {
    const { restaurantId } = useParams();
    const [loading, setLoading] = useState(true);
    const [restaurant, setRestaurant] = useState([]);
    const authState = useSelector((state) => state.auth)
    const restaurantService = new RestaurantService();
    
    const fetchData = async () => {
        setLoading(true)
        try {
            const restaurantData = await restaurantService.fetchRestaurantbyID(restaurantId);
            setRestaurant(restaurantData);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching deliveries:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className='formBackground'>
        <div className="billing-container">
                <div>
                <FormSection title="SUMMARY" fields={[
        { label: "Name", value: restaurant.name },
        { label: "ID", value: restaurant.id },
        { label: "Organization ID", value: restaurant.organizationID },
        { label: "Phone Number", value: restaurant.phoneNumber },
        { label: "Website", value: restaurant.website },
        { label: "URL Path", value: restaurant.urlPath },
        { label: "Average Prep Time", value: `${restaurant.averagePrepTime} minutes` },
        { label: "Minimum Order Without Fee", value: `R ${restaurant.minimumOrderAmountWithoutFee}` },
    ]} />
    <div>
    <FormSection title="LOCATION" fields={[
            { label: "Street Address", value: restaurant.location?.streetAddress },
            { label: "City", value: restaurant.location?.city },
            { label: "State", value: restaurant.location?.state },
            { label: "Postal Code", value: restaurant.location?.postalCode },
            { label: "Country", value: restaurant.location?.country },
            { label: "Country Code", value: restaurant.location?.countryCode },
            { label: "Latitude", value: restaurant.location?.latitude },
            { label: "Longitude", value: restaurant.location?.longitude },
        ]} />

<Link to={`/restaurants/${restaurantId}/menu`}>
                <button className="add-method" >View Menu</button> 
                </Link>
            </div> 
            </div>
        </div>
        </div>
    );
}

export default RestaurantDetailView;