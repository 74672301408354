const formatIsoDateTime = (isoString) => {
    const date = new Date(isoString);

    // Formatting date as "Month Day, Year"
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const readableDate = date.toLocaleDateString(undefined, optionsDate);

    // Formatting time as "HH:MM AM/PM"
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
    const readableTime = date.toLocaleTimeString(undefined, optionsTime);

    return { readableDate, readableTime };
};

export default formatIsoDateTime;