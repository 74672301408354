import {addDoc, collection, deleteDoc, doc, documentId, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { firestoreInstance } from "..";


class DeliveryService {


    async fetchDeliveries(orgID) {
        try {
            if (orgID) {
                const deliveriesCollection = collection(firestoreInstance, `Organizations/${orgID}/Deliveries`);
                const snapshot = await getDocs(deliveriesCollection);
                let deliveries = [];
                snapshot.forEach(doc => {
                    deliveries.push({ id: doc.id, ...doc.data() });
                });
                return deliveries; // You can update the state in your React component with these deliveries
            }
        } catch (error) {
            console.error("Error fetching deliveries: ", error);
        }
    }

    async fetchDeliveryById(orgID, deliveryId) {
        try {
            const docRef = doc(firestoreInstance, `Organizations/${orgID}/Deliveries/${deliveryId}`);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return { id: docSnap.id, ...docSnap.data() };
            } else {
                console.error("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error fetching delivery:", error);
            throw error;
        }
    }

    async cancelDelivery(orgID, deliveryId) {
    try {
        const docRef = doc(firestoreInstance, `Organizations/${orgID}/Deliveries/${deliveryId}`);
        await deleteDoc(docRef);
        return { success: true }; // Return success indicator
    } catch (error) {
        console.error("Error cancelling delivery:", error);
        return { success: false }; // Return failure indicator
    }
}

    async createDelivery(selectedOrg, delivery) {
        try {
            if (selectedOrg?.id) {
                const deliveriesCollection = collection(firestoreInstance, `Organizations/${selectedOrg.id}/Deliveries`);
                const deliveryRef = doc(deliveriesCollection); // Create a reference to a new document
                const deliveryData = {
                    id: deliveryRef.id, // Use the document ID
                    external_id: delivery.external_id || "",
                    urgency_level: delivery.urgency_level,
                    dropoff_address: delivery.dropoff_address,
                    dropoff_latitude: delivery.dropoff_latitude,
                    dropoff_longitude: delivery.dropoff_longitude,
                    dropoff_name: delivery.dropoff_name,
                    dropoff_phone_number: delivery.dropoff_phone_number,
                    deliverable_action: delivery.deliverable_action,
                    dropoff_instructions: delivery.dropoff_instructions || "",
                    pickup_facility_id: delivery.pickup_facility_id || "",
                    pickup_address: delivery.pickup_address,
                    pickup_latitude: delivery.pickup_latitude,
                    pickup_longitude: delivery.pickup_longitude,
                    pickup_name: delivery.pickup_name,
                    pickup_phone_number: delivery.pickup_phone_number,
                    pickup_instructions: delivery.pickup_instructions || "",
                    metadata: delivery.metadata || {},
                    pickup_time: delivery.pickup_time,
                    status: "Created",
                    payment_status: delivery.payment_status || "Unpaid",
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    created_by: "API",
                    updated_by: "API"
                };
    
                await setDoc(deliveryRef, deliveryData); // Save the document with the data
                return { status: "Success", delivery_id: deliveryRef.id };
            }
        } catch (error) {
            console.error("Error creating delivery: ", error);
            throw error;
        }
    }
    
    async createOnDemandDelivery(data) {
        try {
            const deliveryData = {
                ...data,
                pickup_time: new Date().toISOString(),
                status: 'Created',
                payment_status: "Unpaid",
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                created_by: 'API',
                updated_by: 'API'
            };
    
            return await this.createDelivery({ id: data.orgID }, deliveryData);
        } catch (error) {
            console.error("Error creating on-demand delivery: ", error);
            throw error;
        }
    }
    
    async createTimeWindowDelivery(data) {
        try {
            const deliveryData = {
                ...data,
                pickup_window: data.pickup_window,
                dropoff_window: data.dropoff_window,
                status: 'Created',
                payment_status: "Unpaid",
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                created_by: 'API',
                updated_by: 'API'
            };
    
            return await this.createDelivery({ id: data.orgID }, deliveryData);
        } catch (error) {
            console.error("Error creating time-window delivery: ", error);
            throw error;
        }
    }
    
    async createScheduledDelivery(data) {
        try {
            const deliveryData = {
                ...data,
                pickup_time: data.pickup_time,
                status: 'Created',
                payment_status: "Unpaid",
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                created_by: 'API',
                updated_by: 'API'
            };
    
            return await this.createDelivery({ id: data.orgID }, deliveryData);
        } catch (error) {
            console.error("Error creating scheduled delivery: ", error);
            throw error;
        }
    }

}

export default DeliveryService;