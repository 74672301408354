import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import FormSection from '../../Helpers/Components/FormSection';
import formatIsoDateTime from '../../Helpers/Functions/FormatIsoDateTime';
import OrgService from '../../Services/orgService';

function CampaignDetailView() {
    const { campaignId } = useParams();
    const [loading, setLoading] = useState(true);
    const [campaign, setCampaign] = useState(null);
    const [reports, setReports] = useState([]);
    const authState = useSelector((state) => state.auth);
    const orgService = new OrgService();
    
    const fetchData = async () => {
        setLoading(true);
        try {
            const {campaignData, reportData} = await orgService.fetchCampaignbyID(authState.selectedOrganization.id, campaignId);
            setCampaign(campaignData);
            setReports(reportData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching campaign:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [campaignId]);

    if (loading) {
        return <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"}}>
        <div className='loading-indicator'></div>
        </div>;
    }

    if (!campaign) {
        return <div>Campaign not found.</div>;
    }

    const convertTimestampToReadable = (timestamp) => {
        if (!timestamp) {
            console.error("Timestamp is null or undefined");
            return { readableDate: "N/A", readableTime: "N/A" };
        }
        try {
            const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
            const isoString = date.toISOString();
            console.log("ISO String:", isoString);
            const { readableDate, readableTime } = formatIsoDateTime(isoString);
            return { readableDate, readableTime };
        } catch (error) {
            console.error("Error converting timestamp:", error);
            return { readableDate: "Error", readableTime: "Error" };
        }
    };
    const startDateInfo = convertTimestampToReadable(campaign.startDate);
    const endDateInfo = convertTimestampToReadable(campaign.endDate);


    return (
        <div className='formBackground' style={{}}>
            <div className="billing-container">
                <FormSection 
                    title="SUMMARY" 
                    fields={[
                        { label: "Name", value: campaign.name },
                        { label: "Number of Bikes", value: campaign.noOfBikes },
                        { label: "Price Per Bike/month", value: campaign.pricePerBike },
                        { label: "Total Price Per Month", value: campaign.totalMonthlyCost },
                        { label: "Area", value: campaign.area },
                        { label: "Status", value: campaign.status },
                        { label: "Start Date", value: startDateInfo.readableDate },
                        { label: "End Date", value: endDateInfo.readableDate },
                    ]}
                />
                {reports.length > 0 ? reports.map(report => (
                <Link to={`/advertising/${campaignId}/${report.monthYear}`} key={report.id} style={{ textDecoration: 'none', color: 'inherit' }}>

<div style={{ padding: "3px 0px", margin:"0px 0px 5px 0px" }}>
                <div 
        className="hStack" 
        style={{ 
            justifyContent: "space-between", 
            padding: "10px 12px", 
            background: "#fff", 
            borderRadius: "8px", 
            margin: "0px 20px", 
            flexWrap: "wrap"  // Allow wrapping for smaller screens
        }}
    >
        <div className='vStack' style={{ flex: "1 1 100%", marginBottom: "10px" }}>
        <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{report.month} Report</span>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Total Distance</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(report.totalDistance)} KM</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Total Time</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(report.totalTime)} Hours</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Est. Impressions</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(report.estImpressions)}</p>
        </div>
    </div>
            </div>
            </Link>
             )) : null}
            </div>
        </div>
    );
}

export default CampaignDetailView;