import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import OrgService from '../../Services/orgService';

function CampaignMonthlyReport() {
    const { campaignId, monthYear } = useParams();
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState(null);
    const [bikes, setBikes] = useState([]);
    const [downtime, setDowntime] = useState([]);
    const authState = useSelector((state) => state.auth);
    const orgService = new OrgService();
    
    const fetchData = async () => {
        setLoading(true);
        try {
            const {reportData, bikesData, downtimeData} = await orgService.fetchCampaignReport(authState.selectedOrganization.id, campaignId, monthYear);
            setReport(reportData);
            const sortedBikesData = bikesData.sort((a, b) => b.totalTime - a.totalTime);
            setBikes(sortedBikesData);
            const sortedDowntimeData = downtimeData.sort((a, b) => b.totalTime - a.totalTime);
            setDowntime(sortedDowntimeData)
            setLoading(false);
            console.log(reportData);
            console.log(bikesData);
        } catch (error) {
            console.error("Error fetching campaign:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [campaignId, monthYear]);

    if (loading) {
        return <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"}}>
        <div className='loading-indicator'></div>
        </div>;
    }

    if (!report) {
        return <div>Report not found.</div>;
    }


    return (
        <div className="billing-container">
            <div className='hStack' style={{ justifyContent: "space-between" }}>
                <h2>{report.month} {report.year}</h2>
            </div>
            <div className='dividerHorizontal' style={{ margin: "0px 0px 5px 0px" }}></div>
            {loading ? (
                <div style={{display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"}}>
                <div className='loading-indicator'></div>
                </div>
            ) : (
                <div>
<div style={{ padding: "3px 0px", margin:"0px 0px 20px 0px" }}>
                <div 
        className="hStack" 
        style={{ 
            justifyContent: "space-between", 
            padding: "10px 12px", 
            background: "#f1f1f1", 
            borderRadius: "8px", 
            margin: "0px 20px", 
            flexWrap: "wrap"  // Allow wrapping for smaller screens
        }}
    >
        <div className='vStack' style={{ flex: "1 1 100%", marginBottom: "10px" }}>
        <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{"Total"}</span>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Total Distance</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(report.totalDistance)} KM</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Total Time</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(report.totalTime)} Hours</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Est. Impressions</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(report.estImpressions)}</p>
        </div>
    </div>
            </div>
            {bikes.length > 0 ? bikes.map(bike => (
                <div style={{ padding: "3px 0px" }}>
                <div 
        className="hStack" 
        style={{ 
            justifyContent: "space-between", 
            padding: "10px 12px", 
            background: "#f1f1f1", 
            borderRadius: "8px", 
            margin: "0px 20px", 
            flexWrap: "wrap"  // Allow wrapping for smaller screens
        }}
    >
        <div className='vStack' style={{ flex: "1 1 100%", marginBottom: "10px" }}>
            <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{bike.regNo}</span>
            <p className='adBlockText' style={{ color: "var(--accent-color)", margin: "0px", padding: "0px" }}>{bike.status}</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Total Distance</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(bike.totalDistance)} KM</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Total Time</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(bike.totalTime)} Hours</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Est. Impressions</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(bike.estImpressions)}</p>
        </div>
    </div>
            </div>
            )) : <div/>}
            {downtime.length > 0 ? (
               <p style={{ fontWeight: "bold", margin: "20px 0px 0px 20px", padding: "0px" }}>{"Downtime"}</p>
            ) : null}
            {downtime.length > 0 ? downtime.map(bike => (
                <div style={{ padding: "3px 0px" }}>
                <div 
        className="hStack" 
        style={{ 
            justifyContent: "space-between", 
            padding: "10px 12px", 
            background: "#f1f1f1", 
            borderRadius: "8px", 
            margin: "0px 20px", 
            flexWrap: "wrap"  // Allow wrapping for smaller screens
        }}
    >
        <div className='vStack' style={{ flex: "1 1 100%", marginBottom: "10px" }}>
            <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{bike.regNo}</span>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%", paddingRight: "5px" }}>
            <div className="adBlockTitle">Reason</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{bike.reason}</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Downtime</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>{new Intl.NumberFormat('fr-FR').format(100 - bike.totalTime)} Hours</p>
        </div>
        <div className='vStack' style={{ flex: "1 1 30%" }}>
            <div className="adBlockTitle">Discount Value</div>
            <p className='adBlockText' style={{fontWeight: "600"}}>R{new Intl.NumberFormat('fr-FR').format(bike.discountValue)}</p>
        </div>
    </div>

            </div>
            )) : null}
            </div> )}
        </div>
    );
}

export default CampaignMonthlyReport;