import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../../Services/authService';
import Popup from '../Popup';
import '../../../Styles/Helpers/Sidebar.css'

const Sidebar = ({ isSidebarVisible, toggleSidebar }) => {
const [signOutPopup, setSignOutPopup] = useState(false);
const state = useSelector((state) => state.auth);
const dispatch = useDispatch();

const authService = new AuthService();

const togglePopup = () => {
    setSignOutPopup(!signOutPopup);
  };

  const closeSidebarOnMobile = () => {
    const isMobile = window.innerWidth <= 768; // or any other mobile width threshold
    if (isMobile && isSidebarVisible) {
      toggleSidebar();
    }
  };
  

const handleSignOut = () => {
    authService.logout(dispatch);
}

  return (
    <>
    {!isSidebarVisible && 
      <button className="toggle-button" style={{color:"#000"}} onClick={toggleSidebar}>
        {'☰'}
      </button> }
      <div className={isSidebarVisible ? "sidebar-overlay" : ""} onClick={toggleSidebar}></div>
      <div className={`sidebar ${isSidebarVisible ? 'show' : 'hidden'}`}>
        <div className='hstackMimic' style={{marginBottom:"20px"}}>
        <h2 style={{fontWeight: "600", color: "#fff", paddingLeft: "10px"}}>321 Technologies</h2>
        </div>
        <NavLink to="/deliveries" activeclassname="active"onClick={closeSidebarOnMobile}>Deliveries</NavLink>
        <NavLink to="/address" activeclassname="active"onClick={closeSidebarOnMobile}>Address Book</NavLink>
        <NavLink to="/advertising" activeclassname="active"onClick={closeSidebarOnMobile}>Advertising</NavLink>
        <NavLink to="/restaurants" activeclassname="active"onClick={closeSidebarOnMobile}>Restaurants</NavLink>
        <NavLink to="/api" activeclassname="active"onClick={closeSidebarOnMobile}>API</NavLink>
        <NavLink to="/team" activeclassname="active"onClick={closeSidebarOnMobile}>Team</NavLink>
        <div className='sidebar-footer'>
                <p style={{color:"white", margin: "4px 10px", textAlign:"left", padding:"4px 10px 10px 10px", fontWeight:"500"}}>{state.user.fullName}</p>
                <button className='sidebar-sign-out' onClick={togglePopup}>Sign Out</button>
                </div>
      </div>
      <Popup show={signOutPopup} onClose={togglePopup} action={handleSignOut} actionText={"Sign Out"}>
        <h4>Are you sure you would like to sign out?</h4>
        <button className="popup-close-button" onClick={togglePopup}>Cancel</button>
        <button className="popup-action-button" onClick={handleSignOut}>Sign Out</button>
      </Popup>
    </>
  );
};

export default Sidebar;
