import { useState } from 'react';
import './App.css';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Helpers/Contexts/AuthProvider';
import Sidebar from './Views/Helpers/Components/Sidebar';
import { ProtectedRoute } from './Helpers/Objects/ProtectedRoute';
import LoginMain from './Views/Auth/LoginMain';
import CodeVerificationScreen from './Views/Auth/CodeVerificationScreen';
import CreateUserProfile from './Views/Auth/AccountCreation/CreateUserProfile';
import CreateOrganization from './Views/Organizations/CreateOrganization';
import OrganizationInvites from './Views/Organizations/OrganizationInvites';
import BillingSettings from './Views/Settings/BillingSettings';
import TeamSettings from './Views/Settings/TeamSettings';
import AddressBook from './Views/DashboardScreens/AddressBook';
import AdvertisingMain from './Views/DashboardScreens/AdvertisingMain';
import NewCampaign from './Views/Advertising/NewCampaign';
import AllDeliveries from './Views/Deliveries/AllDeliveries';
import DeliveryDetailView from './Views/Deliveries/DeliveryDetailView';
import NewDelivery from './Views/Deliveries/NewDelivery';
import CampaignDetailView from './Views/Advertising/CampaignDetailView';
import CampaignMonthlyReport from './Views/Advertising/CampaignMonthlyReport';
import ApiMain from './Views/Settings/ApiMain';
import RestaurantsMainView from './Views/Restaurants/RestaurantsMainView';
import RestaurantDetailView from './Views/Restaurants/RestaurantDetailView';
import RestaurantsMenuMain from './Views/Restaurants/RestaurantMenuMain';

const MainContent = ({ children, isSidebarVisible }) => {
  return <div className={`main-content ${isSidebarVisible ? 'sidebar-visible' : ''}`}>{children}</div>;
};

function App() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const state = useSelector((state) => state.auth)

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  return (
    <div>
      <BrowserRouter>
      <AuthProvider>
        {state.isSignedIn && state.hasProfile && state.selectedOrganization != null && <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} /> }
        <MainContent isSidebarVisible={isSidebarVisible}>
      <Routes>
      <Route path="/" element={state.isSignedIn && state.hasProfile && state.selectedOrganization != null ? <Navigate to="/deliveries" /> :  <Navigate to="/login" />} />
        <Route element={<ProtectedRoute isAllowed={state.isSignedIn && state.hasProfile && state.selectedOrganization != null } />}>
          <Route path='/billing' element={<BillingSettings />} />
          <Route path='/advertising' element={<AdvertisingMain />} />
          <Route path='/newcampaign' element={<NewCampaign />} />
          <Route path='/team' element={<TeamSettings />} />
          <Route path='/address' element={<AddressBook />} />
          <Route path='/api' element={<ApiMain />} />
          <Route path='/deliveries' element={<AllDeliveries />} />
          <Route path='/restaurants' element={<RestaurantsMainView />} />
          <Route path='/restaurants/:restaurantId' element={<RestaurantDetailView />} />
          <Route path='/restaurants/:restaurantId/menu' element={<RestaurantsMenuMain />} />
          <Route path='/deliveries/:deliveryId' element={<DeliveryDetailView />} /> 
          <Route path='/advertising/:campaignId' element={<CampaignDetailView />} /> 
          <Route path='/advertising/:campaignId/:monthYear' element={<CampaignMonthlyReport />} /> 
          <Route path='/newdelivery' element={<NewDelivery />} />
        <Route path="*" element={<p style={{textAlign: 'center', width:"100%"}}>
          This page does not exist.
        </p>} />
        </Route>
        <Route element={<ProtectedRoute isAllowed={!state.isSignedIn} redirectPath={state.hasProfile ? (state.selectedOrganization == null ? "/organizations" : "/") : "/createprofile"} />}>
        <Route path="/login" element={<LoginMain />} />
        <Route path="/verify" element={<CodeVerificationScreen />} />
        </Route>
        <Route element={<ProtectedRoute isAllowed={!state.hasProfile && state.isSignedIn} redirectPath='/login' />}>
        <Route path="/createprofile" element={<CreateUserProfile />} />
        </Route>
        <Route element={<ProtectedRoute isAllowed={!state.selectedOrganization && state.isSignedIn && state.hasProfile} redirectPath={state.isSignedIn && state.hasProfile && state.selectedOrganization != null ? "/deliveries" : "/login"} />}>
        <Route path='/organizations' element={<OrganizationInvites />} />
        <Route path='/createorganization' element={<CreateOrganization />} />
        </Route>
      </Routes>
      </MainContent>
      </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
