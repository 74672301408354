import React, { useEffect, useState } from 'react';
import MapView from '../Helpers/Components/MapView';
import { LoadScriptNext } from '@react-google-maps/api';
import AddressSearch from '../Helpers/Components/AddressSearch';

const libraries = ['places'];
const AddressBook = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchHidden, setSearchHidden] = useState(false);
  const [address, setAddress] = useState(null);
  const [details, setDetails] = useState({unitNumber: "", buildingName: "", instructions: "", label: ""});
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const handleSearch = (event) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    if (address) {
      setSearchHidden(true);
        let updatedDetails = {...details};
          updatedDetails.unitNumber = address.unitNumber || "";
          updatedDetails.buildingName = address.buildingName || "";
          updatedDetails.label = address.label || "";
          updatedDetails.instructions = address.instructions || "";
          updatedDetails.buildingName = address.name || "";
        setDetails(updatedDetails);
    }else{
      setSearchHidden(false);
    }// eslint-disable-next-line
}, [address])

const saveContinue = () => {
      setAddress({
          ...address,
          unitNumber: details.unitNumber,
          name: details.buildingName,
          instructions: details.instructions,
          label: details.label,
      })
};

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  return (
    <LoadScriptNext googleMapsApiKey={apiKey} libraries={libraries} preventGoogleFontsLoading={true} loadingElement={<div style={{display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"}}>
  <div className='loading-indicator'></div>
  </div>}>
    <div className="billing-container">
    <div className='hStack' style={{ justifyContent: "space-between" }}>
    <h2>Address Book</h2>
    <button className="add-method">+ Add Address</button>
    </div>
    <div className='dividerHorizontal'></div>
    <div className='hStack' style={{gap: "20px", paddingTop: "10px"}}>
      <div style={{width: "100%", maxWidth: "50vw", height: "100%", backgroundColor: "#f1f1f1", padding: "15px", borderRadius: "10px"}}>
    <input type="text" className="searchBox" onChange={handleSearch} style={{margin: "0px 0px 10px 0px"}} placeholder="Search..."/>
      <div className="saved-address-cell">
        <div className='hStack'>
        <h4>JHB Headquarters</h4>
        <p style={{ fontSize: "10px", fontWeight: "550", color: "var(--accent-color)", margin: "0px 0px 0px 5px", padding: "2px 4px", backgroundColor: "rgba(88, 180, 237, 0.2)", borderRadius: "4px" }}>Residential</p>
        </div>
        <p className='truncate-text' style={{fontWeight: "normal", opacity: 0.6, fontSize: "small"}}>5 lower road, morningside, Johannesburg, South Africa</p>
      </div>
      </div>
      <div style={{width: "100%", maxWidth: "50vw", height: "100%", backgroundColor: "#f1f1f1", padding: "15px", borderRadius: "10px"}}>
        <h4>Add new address</h4>
        <MapView lat={address?.latitude ?? 0} lng={address?.longitude ?? 0} height={address != null ? "180px" : "0px"} />
        <AddressSearch onAddressChange={handleAddressChange} />
        { (address) ?
         <>
        <div className={searchHidden ? "hstackMimic" : "hidden hstackMimic"} style={{margin: "10px 15px 10px 15px"}}>
        <p style={{textAlign: "left", padding: "0", margin:"0px 10px 0px 0px", fontWeight: "400", fontSize: "14px", color:"black", flexGrow: "1", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}} className="secondaryText">{address?.fullAddressString}</p>
            <button className="secondaryButtonStyle" onClick={() => setSearchHidden(false)}>Edit</button>
            </div>
          <input value={details.buildingName}
           className='searchBox' 
           placeholder="Building or complex name"
           onChange={(e) => setDetails({...details, buildingName: e.target.value})}
           style={{margin: "5px 0px 5px 0px"}}
            />
            <input value={details.unitNumber}
           className='searchBox'
            placeholder="Apt, flat or floor number"
            onChange={(e) => setDetails({...details, unitNumber: e.target.value})}
            style={{margin: "5px 0px 5px 0px"}}
             />
        <p style={{textAlign: "left", padding: "0", margin:"0px 0px 5px 15px", fontWeight: "600", fontSize: "18px"}}>Delivery Options</p>
        <div className="instruction">
          <input value={details.instructions}
           className='searchBox' 
           placeholder="Instructions for delivery person"
           onChange={(e) => setDetails({...details, instructions: e.target.value})}
           style={{margin: "5px 0px 5px 0px"}}
            />
        </div>
        <input value={details.label}
         className='searchBox' 
         placeholder="Address label"
         onChange={(e) => setDetails({...details, label: e.target.value})}
         style={{margin: "5px 0px 5px 0px"}}
          />
        <button className='primaryButton primaryButtonBox' style={{margin: "20px 0px 0px 0px"}} onClick={saveContinue}>Save and continue</button>
        </>
         : "" }
      </div>
    </div>
</div>
</LoadScriptNext>
  );
};

export default AddressBook;