import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import RestaurantService from '../../Services/restaurantService';

function RestaurantsMainView() {
    const [loading, setLoading] = useState(true);
    const [restaurants, setRestaurants] = useState([]);
    const authState = useSelector((state) => state.auth)
    const restService = new RestaurantService();
    
    const fetchData = async () => {
        setLoading(true)
        try {
            const restData = await restService.fetchRestaurants(authState.selectedOrganization.id);
            setRestaurants(restData);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching deliveries:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="billing-container">
            <div className='hStack' style={{ justifyContent: "space-between" }}>
                <h2>Restaurants</h2>
            </div>
            <div className='dividerHorizontal' style={{ margin: "0px 0px 5px 0px" }}></div>
            {loading ? (
                <div style={{display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"}}>
                <div className='loading-indicator'></div>
                </div>
            ) : (
                <div>
            {restaurants.length > 0 ? restaurants.map(restaurant => (
                <Link to={`/restaurants/${restaurant.id}`} key={restaurant.id} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div style={{ padding: "3px 0px" }}>
                    <div className="hStack" style={{ justifyContent: "space-between", padding: "10px 12px 10px 12px", background: "#f1f1f1", borderRadius: "8px" }}>
                        <div className="vStack" style={{ justifyContent: "space-between" }}>
                                <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{restaurant.name}</span>
                        </div>
                    </div>
                </div>
            </Link>
            )) : <div>
            <p className="friendly-message">You are currently not enrolled in our restaurant program</p>
            <p className="friendly-message">Please contact <a href="mailto:admin@shapshapbikes.co.za">admin@shapshapbikes.co.za</a> if you are interested in delivering with 321</p>
          </div>}
            </div> )}
        </div>
    );
}

export default RestaurantsMainView;