import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../Services/authService";

const CreateOrganization = () => {
    const [businessName, setBusinessName] = useState('');
    const [billingAddress, setBillingAddress] = useState({line1: '',
    line2: '',
    city: '',
    postalCode: ''});
    const [website, setWebsite] = useState('');
    const [supportEmail, setSupportEmail] = useState('');
    const [supportNumber, setSupportNumber] = useState('');
    const navigate = useNavigate()
    const state = useSelector(state => state.auth)
    const authService = new AuthService();
    const dispatch = useDispatch()

    const handleBillingAddressChange = (value, field) => {
        setBillingAddress(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    useEffect(() => {
        setBusinessName(state.selectedInvite.organizationName)
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await authService.createOrganization(businessName, supportEmail, supportNumber, website, billingAddress, state.user, state.uid);
            authService.fetchUserOrganization(state.uid, dispatch)
            navigate("/billing")
            console.log(result);
        } catch (error) {
            // Handle error, e.g., show an error message
            console.error("Error creating organization:", error);
        }
    };

    return (
        <div className="text-form">
            <h1>Tell us about your company</h1>
            <form onSubmit={handleSubmit}>
                <label className="text-form-label">
                    Organization Name
                    <input type="text"
                        value={businessName}
                        onChange={(event) => setBusinessName(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Billing Address
                    <input type="text"
                        value={billingAddress.line1}
                        placeholder="Address line 1"
                        onChange={(event) => handleBillingAddressChange(event.target.value, 'line1')} />
                    <input type="text"
                        value={billingAddress.line2}
                        placeholder="Address line 2"
                        style={{marginTop: "5px"}}
                        onChange={(event) => handleBillingAddressChange(event.target.value, 'line2')} />
                    <input type="text"
                        value={billingAddress.city}
                        placeholder="City"
                        style={{marginTop: "5px", marginBottom: "5px"}}
                        onChange={(event) => handleBillingAddressChange(event.target.value, 'city')} />
                    <input type="text"
                        value={billingAddress.postalCode}
                        placeholder="Postal Code"
                        onChange={(event) => handleBillingAddressChange(event.target.value, 'postalCode')} />
                </label>
                <label className="text-form-label">
                    Country
                    <input
                        type="text"
                        value={"South Africa"}
                        disabled
                        className="disabled-input"
                    />
                </label>
                <label className="text-form-label">
                    Website
                    <input type="text"
                        value={website}
                        onChange={(event) => setWebsite(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Support Phone number
                    <input type="text"
                        value={supportNumber}
                        onChange={(event) => setSupportNumber(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Support email
                    <input type="text"
                        value={supportEmail}
                        onChange={(event) => setSupportEmail(event.target.value)} />
                </label>
                <input className={"primaryButton"} style={{ marginTop: "40px" }} type="submit" value="Create Organization" />
            </form>
        </div>
    );
}

export default CreateOrganization;

