import React, { useEffect, useState } from 'react';
import Popup from '../Helpers/Popup';
import { loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements} from "@stripe/react-stripe-js";
import AuthService from '../../Services/authService';
import { useSelector } from 'react-redux';


const stripePromise = loadStripe('pk_live_51NQlvJJUTIEvfN0jTqzBUlHY2gMfHu9CqLjRrkGKC1VENSIld9GaWbz26L2wMbJ5vNf8WnOBBI2EOxIezW5Ose41004duLXri7');

function BillingSettings() {
  const [newMethodPopup, setNewMethodPopup] = useState(false)
  const authState = useSelector((state) => state.auth)
  const [clientSecret, setClientSecret] = useState('');
  const [paymentMethods, setPaymentMethods] = useState({});
  const [customerID, setCustomerID] = useState('');

  useEffect(() => {
    const authService = new AuthService();
    async function fetchSetupIntent() {
      const { clientSecret, customerID } = await authService.createSetupIntent(authState.selectedOrganization.id);
      setClientSecret(clientSecret);
      setCustomerID(customerID);
    }
    async function fetchPaymentMethods() {
      const methods = await authService.fetchMethods(authState.selectedOrganization.id);
      setPaymentMethods(methods);
      console.log(methods)
    }

    fetchSetupIntent();
    fetchPaymentMethods()
  }, []);

  const handleCardSubmission = async (event) => {
    event.preventDefault();

    const stripe = await stripePromise;

    // **Create payment method token (client-side):**
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: event.target.elements[0], // Access the CardElement instance
    });

    if (error) {
      // Handle potential errors during card creation
      console.error(error.message);
      // You can display an error message to the user here
      return; // Exit the function if there's an error
    }

    // **Send token to server for attachment (secure communication):**
    const response = await fetch('/api/attach-payment-method', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        customerID, // Replace with the actual customer ID
        paymentMethodToken: paymentMethod.id, // Send the token, not raw card details
      }),
    });

    if (!response.ok) {
      // Handle potential errors from the backend
      console.error('Error attaching payment method:', await response.text());
      // You can display an error message to the user here
      return; // Exit the function if there's an error
    }

    console.log('Payment method attached successfully!');
    // You can display a success message to the user or refresh data
    setNewMethodPopup(false); // Close the popup
  };

  const options = React.useMemo(() => ({
    clientSecret, 
  }, [clientSecret]))


  return (
    clientSecret === "" ? (
      <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"}}>
    <div className='loading-indicator'></div>
    </div>
    ) : (
    <Elements stripe={stripePromise} options={options}>
    <div className="billing-container">
        <h2>Billing Settings</h2>
        <div className='dividerHorizontal'></div>
      <div>
          <h3>Pay per trip</h3>
        <div className="payment-methods">
          <h2>Payment method(s)</h2>
          {paymentMethods.length > 0 ? (
  paymentMethods.map(method => (
    <div className="payment-method">
            <h4>••••{method.card.last4}</h4>
            <p style={{fontWeight: "normal", opacity: 0.6, fontSize: "small"}}>Expires {method.card.exp_month}/{method.card.exp_year}</p>
            <div className="default-payment-method">Default</div>
            <div className="default-payment-method" style={{color: "var(--accent-color)", fontWeight: "bold", padding: "0px 0px 0px 10px"}}>Delete</div>
          </div>
  ))
) : (
  <div>No Methods Yet</div>
)}
          <button className="add-method" onClick={() => {setNewMethodPopup(true)}}>+ Add payment method</button>
        </div>

        <div className="statements">
          <h2>Statements</h2>
          <p>Monthly statements received by 1 users.</p>
          <div className="statement-list">
            {/* This could be a list rendered from state or props */}
            <div className="statement-item">
              <p>Date</p>
              <p>Balance</p>
            </div>
            <div className="statement-item">
              <p>December 2023</p>
              <p>ZAR 0</p>
            </div>
          </div>
        </div>

        <div className="service-fees">
          <h2>Service fees</h2>
          <p>No service fees charged</p>
        </div>
      </div>
      <Popup show={newMethodPopup} onClose={() => {setNewMethodPopup(false)}}>
      <h2>Add payment method</h2>
        <form onSubmit={() => {}}>
          <CardElement />
          <button className="primaryButton" style= {{marginTop: "20px"}} type="submit">Save</button>
        </form>
            </Popup>
    </div>
    </Elements>
    )
  )
}

export default BillingSettings;
