import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Services/authService";
import { setSelectedInvite } from "../../Redux/Actions/authActions";

const OrganizationInvites = () => {
    const [invitations, setInvitations] = useState([])
    const [loading, setLoading] = useState(true);
    const state = useSelector(state => state.auth);
    const navigate = useNavigate();
    const phoneNumber = state.user.phoneNumber;
    const authService = new AuthService();
    const dispatch = useDispatch();
    
    useEffect(() => {
        const fetchInvitations = async () => {
            const invites = await authService.checkOrganizationInvites(phoneNumber);
            setInvitations(invites);
            setLoading(false);
        };
    
        fetchInvitations();
    }, [phoneNumber])
    


    const handleAccept = (invite) => {
        authService.joinOrganization(state.user, invite.orgID, invite.id)
        authService.fetchUserOrganization(state.uid, dispatch)
        console.log(`Accepting invite: ${invite.id}`);
    };

    const handleCreate = (invite) => {
        dispatch(setSelectedInvite(invite))
        navigate("/createorganization")
    };

    return (
        <div className="text-form">
        <h1 style={{textAlign: "left"}}>You're Almost There!</h1>
        <h2 style={{textAlign: "left", fontWeight: "bold"}}>Invitations</h2>
            {invitations.length > 0 ? invitations.map(invite => (
                <div key={invite.id} className="invitation" style={{padding: "5px"}}>
                    {invite.type === 'create' ? (
                        <div className="invitationsSection">
                            <div className="hStack" style={{justifyContent: "space-between", padding: "10px 12px 10px 12px", background: "#f1f1f1", borderRadius: "8px"}}>
                            <div className="vStack" style={{justifyContent: "space-between"}}>
                            <h4 style={{fontWeight: "bold", margin: "0px", padding: "0px"}}>{invite.organizationName}</h4>
                            <p style={{fontSize: "small", margin: "0px", padding: "0px"}}>New organization</p>
                            </div>
                            <button 
                            style={{padding: "10px 12px", fontSize: "16px", fontWeight: "550", backgroundColor: "var(--accent-color)", color: "#f1f1f1", border: "none", borderRadius: "8px", cursor: "pointer"}} 
                            onClick={() => handleCreate(invite)}>
                                Create
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="invitationsSection">
                            <div className="hStack" style={{justifyContent: "space-between", padding: "10px 12px 10px 12px", background: "#f1f1f1", borderRadius: "8px"}}>
                            <div className="vStack" style={{justifyContent: "space-between"}}>
                            <h4 style={{fontWeight: "bold", margin: "0px", padding: "0px"}}>{invite.organizationName}</h4>
                            <p style={{fontSize: "small", margin: "0px", padding: "0px"}}>{invite.role}</p>
                            </div>
                            <button 
                            style={{padding: "10px 12px", fontSize: "16px", fontWeight: "550", backgroundColor: "var(--accent-color)", color: "#f1f1f1", border: "none", borderRadius: "8px", cursor:"pointer"}} 
                            onClick={() => handleAccept(invite)}>
                                Accept
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )) : (
            <div>
    <p className="friendly-message">Currently, there are no active invitations linked to your account.</p>
    <p className="friendly-message">If you believe you should have an invitation, please check with your company's administrator. They can assist you with the access process.</p>
    <p className="friendly-message">For owners and administrators needing support or looking to set up an organization account, we're here to help at <a href="mailto:admin@shapshapbikes.co.za">admin@shapshapbikes.co.za</a>.</p>
</div>
        )}
    </div>
    );
}

export default OrganizationInvites;

