import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Popup from '../Helpers/Popup';
import OrgService from '../../Services/orgService';

function TeamSettings() {
    const [invitePopup, setInvitePopup] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(true);
    const [inviteRole, setInviteRole] = useState("Admin");
    const [selectedMember, setSelectedMember] = useState(null);
    const [editPopup, setEditPopup] = useState(false);
    const [removeWarning, setRemoveWarning] = useState(false);
    const [team, setTeam] = useState([]);
    const [invited, setInvited] = useState([]);
    const authState = useSelector((state) => state.auth)
    const orgService = new OrgService();
    
    const fetchData = async () => {
        setLoading(true)
        try {
            const teamData = await orgService.fetchTeam(authState.selectedOrganization.id);
            setTeam(teamData.team);
            setInvited(teamData.invites);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching team data:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        if (selectedMember != null) {
            setRemoveWarning(false)
            setEditPopup(true)
        }else{
            setEditPopup(false)
            setRemoveWarning(false)
        }
    }, [selectedMember])

    const handleRoleChange = (event) => {
        setInviteRole(event.target.value);
    };
    const toggleInvitePopup = () => {
        setInvitePopup(!invitePopup);
    };
    useEffect(() => {
        fetchData();
    }, [])
    const handleInputChange = (event) => setPhoneNumber(event.target.value);
    const handleInvite = async () => {
        try {
            await orgService.inviteTeamMember(`+27${phoneNumber}`, inviteRole, authState.selectedOrganization, authState.user.id)
            toggleInvitePopup()
            setPhoneNumber("")
            setInviteRole("Admin")
            fetchData()
        } catch (error) {
            console.error("Error inviting team member:", error);
        }
        
    };
    const handleEdit = (member) => {
        setSelectedMember(member)
        setInviteRole(member.role)
    };
    const handleDeleteMember = async () => {
        try {
            if (selectedMember.id == authState.user.id) return
            await orgService.deleteMember(selectedMember.id, authState.selectedOrganization.id)
            setSelectedMember(null)
            fetchData()
        } catch (error) {
            console.error("Error deleting member:", error);
        }
    };
    const handleUpdateRole = async () => {
        try {
            await orgService.changeRole(selectedMember.id, authState.selectedOrganization.id, inviteRole)
            setSelectedMember(null)
            fetchData()
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };
    const handleDeleteInvite = async (inviteID) => {
        try {
            await orgService.deleteInvite(inviteID, authState.selectedOrganization.id)
            fetchData()
        } catch (error) {
            console.error("Error deleting invite:", error);
        }
    };

    return (
        <div className="billing-container">
            <div className='hStack' style={{ justifyContent: "space-between" }}>
                <h2>Team</h2>
                {(authState.selectedOrganization.role == "Admin") &&
                <button className="add-method" onClick={() => toggleInvitePopup()}>Invite Member</button> }
            </div>
            <div className='dividerHorizontal' style={{ margin: "0px 0px 5px 0px" }}></div>
            {loading ? (
                <div style={{display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"}}>
                <div className='loading-indicator'></div>
                </div>
            ) : (
                <div>
            {team.length > 0 ? team.map(member => (
                <div key={member.id} style={{ padding: "3px 0px" }}>
                    <div className="hStack" style={{ justifyContent: "space-between", padding: "10px 12px 10px 12px", background: "#f1f1f1", borderRadius: "8px" }}>
                        <div className="vStack" style={{ justifyContent: "space-between" }}>
                            <div className='hStack'>
                                <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{member.fullName}</span>
                                {member.id == authState.user.id && <p style={{ fontSize: "10px", fontWeight: "550", color: "var(--accent-color)", margin: "0px 0px 0px 5px", padding: "2px 4px", backgroundColor: "rgba(88, 180, 237, 0.2)", borderRadius: "4px" }}>You</p>}
                            </div>
                            <p style={{ fontSize: "small", margin: "0px", padding: "0px" }}>{member.phoneNumber}</p>
                        </div>
                        <div className='hStack' style={{ gap: "5px" }}>
                            <p style={{ fontSize: "medium", margin: "0px", padding: "10px 12px 10px 12px", fontWeight: "500", background: "#f1f1f1", borderRadius: "8px" }}>{member.role}</p>
                            {(authState.selectedOrganization.role === "Admin" && member.id !== authState.user.id) &&
                            <button
                                style={{ padding: "10px 12px", fontSize: "16px", fontWeight: "550", backgroundColor: "rgba(88, 180, 237, 0.2)", color: "var(--accent-color)", border: "none", borderRadius: "8px", cursor: "pointer" }}
                                onClick={() => handleEdit(member)}>
                                Edit
                            </button>
}
                        </div>
                    </div>
                </div>
            )) : null}
            {invited.length > 0 ? invited.map(invite => (
                <div key={invite.id} style={{ padding: "3px 0px" }}>
                    <div className="hStack" style={{ justifyContent: "space-between", padding: "10px 12px 10px 12px", background: "#f1f1f1", borderRadius: "8px" }}>
                        <div className="vStack" style={{ justifyContent: "space-between" }}>
                            <div className='hStack'>
                                <span style={{ fontWeight: "550", margin: "0px", padding: "0px" }}>{invite.phoneNumber}</span>
                                <p style={{ fontSize: "10px", fontWeight: "550", color: "var(--accent-color)", margin: "0px 0px 0px 5px", padding: "2px 4px", backgroundColor: "rgba(88, 180, 237, 0.2)", borderRadius: "4px" }}>Invite Pending</p>
                            </div>
                        </div>
                        <div className='hStack' style={{ gap: "5px" }}>
                            <p style={{ fontSize: "medium", margin: "0px", padding: "10px 12px 10px 12px", fontWeight: "500", background: "#f1f1f1", borderRadius: "8px" }}>{invite.role}</p>
                            {(authState.selectedOrganization.role === "Admin") &&
                            <button
                                style={{ padding: "10px 12px", fontSize: "16px", fontWeight: "550", backgroundColor: "rgba(236, 0, 0, 0.2)", color: "red", border: "none", borderRadius: "8px", cursor: "pointer" }}
                                onClick={() => handleDeleteInvite(invite.id)}>
                                Delete
                            </button>
}
                        </div>
                    </div>
                </div>
            )) : null}
            </div> ) }
            <Popup show={invitePopup} onClose={toggleInvitePopup}>
                <div className='vStack'>
                    <h3 style={{ margin: "0px 0px 20px 0px" }}>Invite Team Member</h3>
                    <div className='hStack' style={{ justifyContent: "space-between" }}>
                        <div className='vStack text-form' style={{ margin: "0px 10px 0px 0px", padding: "0px" }}>
                            <p className="text-form-label">Phone Number</p>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                            }}>
                                <span style={{
                                    backgroundColor: '#f0f0f0',
                                    padding: '12px 10px',
                                    borderRadius: '8px',
                                    border: '0px solid #ccc',
                                    marginRight: "5px"
                                }}>+27</span>
                                <input
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={handleInputChange}
                                    placeholder="Mobile Number"
                                    style={{
                                        width: '100%',
                                        minWidth: "160px",
                                        flex: 1,
                                        padding: '12px 10px',
                                        fontSize: '16px',
                                        backgroundColor: '#f0f0f0',
                                        borderRadius: '8px',
                                        border: '0px solid #ccc',
                                        outline: 'none',
                                    }}
                                />
                            </div>
                        </div>
                        <div className='vStack text-form' style={{ margin: "0", padding: "0px" }}>
                            <p className="text-form-label">Role</p>
                            <select
                                value={inviteRole}
                                onChange={handleRoleChange}
                                style={{
                                    padding: '12px 10px',
                                    fontSize: '16px',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '8px',
                                    border: '0px solid #ccc',
                                    outline: 'none',
                                    WebkitAppearance: 'none', // For Safari and WebKit-based browsers
                                    MozAppearance: 'none', // For Firefox
                                }}
                            >
                                <option value="Admin">Admin</option>
                                <option value="Member">Member</option>
                            </select>
                        </div>
                    </div>
                    <div className='' style={{
                        display: 'flex', width: "100%", // Ensures the container is a flex container
                        justifyContent: 'flex-end', gap: "5px", marginTop: "20px"
                    }}>
                        <button
                            style={{ padding: "8px 10px", fontSize: "14px", fontWeight: "500", backgroundColor: "#f0f0f0", color: "black", border: "none", borderRadius: "8px", cursor: "pointer" }}
                            onClick={()=>{toggleInvitePopup()}}
                        >
                            Cancel
                        </button>
                        <button
                            style={{ padding: "8px 10px", fontSize: "14px", fontWeight: "550", backgroundColor: "var(--accent-color)", color: "#f1f1f1", border: "none", borderRadius: "8px", cursor: "pointer", opacity: (phoneNumber.length !== 9) ? "0.5" : "1.0" }}
                            onClick={()=>{handleInvite()}}
                            disabled={(phoneNumber.length !== 9)}
                        >
                            Invite
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup show={editPopup} onClose={() => {setSelectedMember(null)}}>
                {selectedMember &&
                <div className='vStack' style={{width: "320px"}}>
                    <h3 style={{ margin: "0px" }}>{selectedMember.fullName}</h3>
                    <p className='text-form-label' style={{ margin: "0px", padding: "0px" }}>{selectedMember.phoneNumber}</p>
                        <p className='text-form-label' style={{ margin: "0px 0px 30px 0px", padding: "0px" }}>{selectedMember.email}</p>
                    <div className='hStack' style={{ justifyContent: "space-between", gap: "5px", width: "100%", marginBottom: "15px"}}>
                    <p style={{ fontSize: "small", margin: "0px", padding: "0px" }}>Change Role</p>
                            <select
                                value={inviteRole}
                                onChange={handleRoleChange}
                                style={{
                                    padding: '8px 10px',
                                    fontSize: '14px',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '8px',
                                    border: '0px solid #ccc',
                                    outline: 'none',
                                    WebkitAppearance: 'none', // For Safari and WebKit-based browsers
                                    MozAppearance: 'none', // For Firefox
                                }}
                            >
                                <option value="Admin">Admin</option>
                                <option value="Member">Member</option>
                            </select>
                    </div>
                    {(selectedMember.id != authState.user.id) && (
                     <div className='hStack' style={{justifyContent: "space-between", width: "100%"}}>
                    <p style={{ fontSize: "small", margin: "0px", padding: "0px" }}>Remove from Team</p>
                    <button
                            style={{ padding: "8px 10px", fontSize: "14px", fontWeight: "550", backgroundColor: removeWarning ? "var(--accent-color)" : "red", color: "#f1f1f1", border: "none", borderRadius: "8px", cursor: "pointer" }}
                            onClick={()=>{setRemoveWarning(!removeWarning)}}
                        >
                           {removeWarning ? "Cancel" : "Remove"}
                        </button>
                        </div>
                        )}
                        {(removeWarning) && (
                             <div className='hStack' style={{justifyContent: "space-between", width: "100%", marginTop: "15px"}}>
                    <p style={{ fontSize: "small", margin: "0px", padding: "0px" }}>Are you sure you would like to remove this team member, this action cannot be undone.</p>
                    <button
                            style={{ padding: "8px 10px", fontSize: "14px", fontWeight: "550", backgroundColor: "red", color: "#f1f1f1", border: "none", borderRadius: "8px", cursor: "pointer" }}
                            onClick={handleDeleteMember}
                        >
                            Remove
                        </button>
                    
                        </div>)}
                   
                    <div className='' style={{
                        display: 'flex', width: "100%", // Ensures the container is a flex container
                        justifyContent: 'flex-end', gap: "5px", marginTop: "30px"
                    }}>
                        <button
                            style={{ padding: "8px 10px", fontSize: "14px", fontWeight: "500", backgroundColor: "#f0f0f0", color: "black", border: "none", borderRadius: "8px", cursor: "pointer" }}
                            onClick={() => {setSelectedMember(null)}}
                        >
                            Cancel
                        </button>
                        <button
                            style={{ padding: "8px 10px", fontSize: "14px", fontWeight: "550", backgroundColor: "var(--accent-color)", color: "#f1f1f1", border: "none", borderRadius: "8px", cursor: "pointer", opacity: (selectedMember.role == inviteRole) ? "0.5" : "1.0"}}
                            onClick={handleUpdateRole}
                            disabled={(selectedMember.role == inviteRole)}
                        >
                            Update
                        </button>
                    </div>
                </div>
}
            </Popup>
        </div>
    );
}

export default TeamSettings;
