import React, { useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, LoadScriptNext } from '@react-google-maps/api';

const libraries = ['marker'];

const MapViewTwoMarkers = ({ location1, location2, name1, name2 }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const mapRef = useRef(null);

  const isValidLocation = (location) => {
    return location && typeof location.lat === 'number' && typeof location.lng === 'number';
  };

  useEffect(() => {
    if (mapRef.current && isValidLocation(location1) && isValidLocation(location2)) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(location1);
      bounds.extend(location2);
      mapRef.current.fitBounds(bounds);
  
      const marker1 = new window.google.maps.marker.AdvancedMarkerElement({
        position: location1,
        map: mapRef.current,
        title: `${name1}`,
      });
  
      const marker2 = new window.google.maps.marker.AdvancedMarkerElement({
        position: location2,
        map: mapRef.current,
        title: `${name2}`,
      });
    }
  }, [location1, location2]);

  if (!isValidLocation(location1) || !isValidLocation(location2)) {
    return <div>Invalid location data provided.</div>;
  }

  const containerStyle = {
    margin: '0px 15px',
    boxSizing: 'content-box',
    height: "180px",
    borderRadius: '15px',
    transition: "height 0.3s ease",
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    scrollwheel: false,
    draggable: true,
    disableDoubleClickZoom: true,
    mapId: 'YOUR_MAP_ID', // Replace with your actual Map ID
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  return (
    <LoadScriptNext
      googleMapsApiKey={apiKey}
      libraries={libraries}
      id="script-loader"
      loadingElement={<div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center"}}>
    <div className='loading-indicator'></div>
    </div>}
      strategy="afterInteractive"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        onLoad={(map) => (mapRef.current = map)}
      />
    </LoadScriptNext>
  );
};

export default MapViewTwoMarkers;