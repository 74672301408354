export const SET_USER = "SET_USER";
export const SET_SIGN_IN_STATE = "SET_SIGN_IN_STATE";
export const SET_SELECTED_INVITE = "SET_SELECTED_INVITE";
export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";

const setSignInState = (signedIn, uid) => {;
        const load = {signedIn: signedIn, uid: uid};
        return {
        type: SET_SIGN_IN_STATE,
        payload: load,
}};

const setUser = (user, hasProfile) => {
        const load = {user: user, hasProfile: hasProfile};
        return {
        type: SET_USER,
        payload: load,
}};

const setSelectedInvite = (invite) => {
        return {
        type: SET_SELECTED_INVITE,
        payload: invite,
}};

const setSelectedOrganization = (organization) => {
        return {
        type: SET_SELECTED_ORGANIZATION,
        payload: organization,
}};

export { setUser, setSignInState, setSelectedInvite, setSelectedOrganization };