import React from 'react';

const FormSection = ({ title, fields }) => {
    return (
        <div className="infoSection">
            {title && <div className="formTitle">{title}</div>}
            <div className="formSection">
                {fields.map((field, index) => (
                    <React.Fragment key={index}>
                        <div className='hStack' style={{ justifyContent: "space-between", padding: "0px 10px" }}>
                            <p>{field.label}</p>
                            <p>{field.value}</p>
                        </div>
                        {index < fields.length - 1 && <div className="dividerHorizontal" />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default FormSection;