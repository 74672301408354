import React, { useEffect, useRef } from 'react';

function initMap(onAddressChange, inputId) {

  if (!window.google) {
    console.error("Google Maps JavaScript API is not loaded");
    return;
  }
  const searchInput = document.getElementById(inputId);
    
  // Initialize the Google Autocomplete
  // eslint-disable-next-line no-undef
  const autocomplete = new google.maps.places.Autocomplete(searchInput, {
    componentRestrictions: { country: 'za' },  // Optional: restrict to specific country
    fields: ['address_components', 'geometry', 'name', 'types', 'formatted_address']
  });

  // Add listener to handle place changes
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (!place || !place.geometry || !place.geometry.location) {
      console.error("Failed to retrieve place details.");
      return;
  }
  
  const fullAddressString = place.formatted_address || searchInput.value;
  const latitude = place.geometry.location.lat();
  const longitude = place.geometry.location.lng();
  const addressComponents = place.address_components || [];
  
  const findComponent = (type) => addressComponents.find(comp => comp.types.includes(type));
  
  const streetNumberComponent = findComponent('street_number');
  const streetNumber = streetNumberComponent ? streetNumberComponent.long_name : '';
  
  const streetNameComponent = findComponent('route');
  const streetName = streetNameComponent ? streetNameComponent.long_name : '';
  
  const areaComponent = findComponent('sublocality') || findComponent('sublocality_level_1');
  const area = areaComponent ? areaComponent.long_name : '';
  
  const streetAddress = `${streetNumber} ${streetName}, ${area}`;
  
  const cityComponent = findComponent('locality');
  const city = cityComponent ? cityComponent.long_name : '';
  
  const countryComponent = findComponent('country');
  const country = countryComponent ? countryComponent.long_name : '';
  const countryCode = countryComponent ? countryComponent.short_name : '';
  
  const businessOrBuildingName = place.name || '';
  const isBuildingOrBusiness = place.types.includes('premise') || place.types.includes('subpremise') || place.types.includes('point_of_interest');
  
  const name = isBuildingOrBusiness ? businessOrBuildingName : "";
    
  const addressDetails = {
    latitude,
    longitude,
    city,
    country,
    countryCode,
    streetAddress,
    name,
    fullAddressString
  };

  onAddressChange(addressDetails);
  });
}

export default function AddressSearch({ onAddressChange, inputId }) {
  const searchInput = useRef(null);

  useEffect(() => {
    initMap(onAddressChange, inputId);
  }, [onAddressChange, inputId]);

  return (
    <div>
      <input
        ref={searchInput}
        className="addressBox"
        id={inputId}
        placeholder="Enter your address"
        type="text"
      />
    </div>
  );
}