import {Timestamp, addDoc, collection, collectionGroup, deleteDoc, doc, documentId, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { firestoreInstance } from "..";
import { setSelectedOrganization } from "../Redux/Actions/authActions";
import axios from 'axios';

class RestaurantService {

    async editRestaurant(orgID, bikes, term, activationFee, monthlyFee, startedBy) {
        try {
            const campaignData = {
                noOfBikes: bikes,
                startDate: Timestamp.now(),
                status: "Review",
                term: term,
                activationFee: activationFee,
                monthlyFee: monthlyFee,
                startedBy: startedBy,
            }
            const adsColl = collection(firestoreInstance, "Organizations/" + orgID + "/AdvertisingCampaigns")
            await addDoc(adsColl, campaignData)
            return "Success";
        } catch (error) {
            console.log(error)
            throw error;
        }
    }


    async fetchRestaurants(orgID) {
        try {
            const restColl = collection(firestoreInstance, "Restaurants");
            const restQuery = query(restColl, where("organizationID", "==", orgID));  // Use a different name for the query
            const snapshot = await getDocs(restQuery);  // Pass the query instead of the collection to getDocs
            const restData = snapshot.docs.map(doc => ({
                ...doc.data() // Include the other fields from the document data
            }));
            return restData;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async fetchRestaurantMenu(restaurantId) {
        try {
            const restColl = collection(firestoreInstance, `Restaurants/${restaurantId}/Menu`);
        const snapshot = await getDocs(restColl);
        
        // Filter out the document with the ID 'menuConfig'
        const restData = snapshot.docs
            .filter(doc => doc.id !== 'menuConfig')  // Exclude 'menuConfig' document
            .map(doc => ({
                id: doc.id,  // Include the document ID if needed
                ...doc.data() // Include the other fields from the document data
            }));
        
        return restData;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async fetchRestaurantbyID(restaurantID) {
        try {
            const restDoc = doc(firestoreInstance, `Restaurants/${restaurantID}`); // Use a different name for the query
            const snapshot = await getDoc(restDoc);  // Pass the query instead of the collection to getDocs
            const restData = snapshot.data();
            return restData;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async fetchCampaignbyID(orgID, campaignID) {
        try {
            const campaignDoc = doc(firestoreInstance, "Organizations/" + orgID + "/AdvertisingCampaigns/" + campaignID)
            const reportsColl = collection(firestoreInstance, "Organizations/" + orgID + "/AdvertisingCampaigns/" + campaignID + "/Reports")
            const snapshot = await getDoc(campaignDoc)
            if (!snapshot.exists()) {
                throw new Error(`Campaign with ID ${campaignID} not found.`);
            }
            const reportSnapshot = await getDocs(reportsColl)
            const reportData = reportSnapshot.docs.map(doc => ({
                id: doc.id, // Add the document ID as the 'id' field
                ...doc.data() // Include the other fields from the document data
            }));
            const campaignData = snapshot.data();
            return {campaignData, reportData};
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async fetchCampaignReport(orgID, campaignID, monthYear) {
        try {
            const reportsColl = collection(firestoreInstance, "Organizations/" + orgID + "/AdvertisingCampaigns/" + campaignID + "/Reports")
            const reportQuery = query(reportsColl, where("monthYear", "==", monthYear));

        // Execute the query
        const querySnapshot = await getDocs(reportQuery);
        if (!querySnapshot.empty) {
            const reportDoc = querySnapshot.docs[0]; 
            const bikesColl = collection(firestoreInstance, reportDoc.ref.path + "/Bikes")
            const bikesSnapshot = await getDocs(bikesColl)
            const bikesData = bikesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            const reportData = reportDoc.data();
            console.log('Report Data:', reportData);
            return {reportData, bikesData};
        } else {
            console.log('No report found for this month and year.');
            return null;
        }
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async inviteTeamMember(number, role, organization, userID) {
        try {
            const inviteData = {
                phoneNumber: number,
                role: role,
                orgID: organization.id,
                organizationName: organization.name,
                invitedBy: userID,
            }
            const orgID = organization.id;
            const invitesColl = collection(firestoreInstance, "Organizations/" + orgID + "/TeamInvites")
            await addDoc(invitesColl, inviteData)
            return "Success";
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async deleteInvite(inviteID, orgID) {
        try {
            const inviteDoc = doc(firestoreInstance, "Organizations/" + orgID + "/TeamInvites/" + inviteID)
            await deleteDoc(inviteDoc)
            return "Success";
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async fetchTeam(orgID) {
        try {
            const teamColl = collection(firestoreInstance, "Organizations/" + orgID + "/Team")
            const invitesColl = collection(firestoreInstance, "Organizations/" + orgID + "/TeamInvites")
            const teamSnapshot = await getDocs(teamColl)
            const inviteSnapshot = await getDocs(invitesColl)
            const teamData = teamSnapshot.docs.map(doc => doc.data());
            const inviteData = inviteSnapshot.docs.map(doc => ({
                id: doc.id, // Add the document ID as the 'id' field
                ...doc.data() // Include the other fields from the document data
            }));
            // Return an object containing both arrays
            return { team: teamData, invites: inviteData };
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async changeRole(uid, orgID, role) {
        try {
            const userDoc = doc(firestoreInstance, "Organizations/" + orgID + "/Team/" + uid)
            await updateDoc(userDoc, { role: role})
            return "Success";
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async deleteMember(uid, orgID) {
        try {
            const userDoc = doc(firestoreInstance, "Organizations/" + orgID + "/Team/" + uid)
            await deleteDoc(userDoc)
            return "Success";
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async createOrganization(name, email, number, website, billingAddress, user, uid) {
        try {
            const coll = collection(firestoreInstance, "Organizations")
            const orgData = {
                name: name,
                supportEmail: email,
                supportPhoneNumber: number,
                website: website,
                billingAddress: billingAddress,
                adminSet: false,
            }
            const orgDoc = await addDoc(coll, orgData)
            const orgID = orgDoc.id;
            const adminDoc = doc(firestoreInstance, "Organizations/" + orgID + "/Team/" + uid)
            const adminData = {
                fullName: user.fullName,
                phoneNumber: user.phoneNumber,
                email: user.email,
                id: uid,
                role: "Admin"
            }
            await setDoc(adminDoc, adminData)
            await updateDoc(orgDoc, { adminSet: true, id: orgID })
            return "Success";
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async fetchUserOrganization(uid, dispatch) {
        try {
            // Query the Team collection group for the user's document 
            const teamquery = query(collectionGroup(firestoreInstance, "Team"), where("id", "==", uid));
            const querySnapshot = await getDocs(teamquery);
            if (!querySnapshot.empty) {
                // Assuming the user is only part of one team
                const userTeamDoc = querySnapshot.docs[0];
                const orgID = userTeamDoc.ref.parent.parent.id;
                // Fetch the organization data
                const orgSnapshot = await getDoc(doc(firestoreInstance, `Organizations/${orgID}`));
                if (orgSnapshot.exists()) {
                    const orgData = orgSnapshot.data();
                    // Merge user data with organization data and dispatch
                    dispatch(setSelectedOrganization(orgData));
                } else {
                    console.log("Organization not found for the team");
                    // Handle organization not found
                }
            } else {
                console.log("User is not part of any team");
                // Handle case where the user is not part of any team
            }
        } catch (error) {
            console.log("Error fetching user organization:", error);
            throw error;
        }
    }

    async checkOrganizationInvites(phoneNumber) {
        try {
            const querySnapshot = await getDocs(query(collection(firestoreInstance, "OrganizationInvitations"), where("phoneNumber", "==", phoneNumber)));
            if (!querySnapshot.empty) {
                const invitations = [];
                querySnapshot.forEach((docSnapshot) => {
                    const invitationData = { id: docSnapshot.id, ...docSnapshot.data() };
                    invitations.push(invitationData);
                });
                return invitations;
            } else {
                console.log("No invitations found for this phone number");
                return [];
            }
        } catch (error) {
            console.log(error)
            throw error;
        }
    }


}

export default RestaurantService;