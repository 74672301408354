import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormSection from '../../Helpers/Components/FormSection';

function ApiMain() {
    const [loading, setLoading] = useState(true);
    const authState = useSelector((state) => state.auth);
    
    const fetchData = async () => {
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div className='formBackground' style={{height: "100vh"}}>
            <div className="billing-container">
            <div className="infoSection">
            <div className="formTitle">{"Keys"}</div>
            <div className="formSection">
                   
                        <div className='hStack' style={{ justifyContent: "space-between", padding: "0px 10px" }}>
                            <p>API Key</p>
                            <p 
                                style={{
                                    maxWidth: "60%",  // Adjust as necessary
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer"
                                }}
                                title={authState.selectedOrganization.apiKey}  // Tooltip with full text on hover
                                onClick={() => navigator.clipboard.writeText(authState.selectedOrganization.apiKey)} // Click to copy
                            >
                                {authState.selectedOrganization.apiKey}
                            </p>
                        </div>
                        <div className="dividerHorizontal" />
                        <div className='hStack' style={{ justifyContent: "space-between", padding: "0px 10px" }}>
                            <p>Test Key</p>
                            <p 
                                style={{
                                    maxWidth: "60%",  // Adjust as necessary
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer"
                                }}
                                title={authState.selectedOrganization.testApiKey}  // Tooltip with full text on hover
                                onClick={() => navigator.clipboard.writeText(authState.selectedOrganization.testApiKey)} // Click to copy
                            >
                                {authState.selectedOrganization.testApiKey}
                            </p>
                        </div>
               
            </div>
        </div>
                <FormSection 
                    title="Logs" 
                    fields={[
                    ]}
                />
                <div style={{padding: "0px 30px"}}>
    <p className="friendly-message">Your API requests will show up here after your first use</p>
    <p className="friendly-message">Go to <a href="https://docs.321delivery.co.za" target="_blank" rel="noopener noreferrer">docs.321delivery.co.za</a> For information on how to integrate the API into your app or website</p>
</div>
            </div>
        </div>
    );
}

export default ApiMain;