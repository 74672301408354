import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DeliveryService from '../../Services/deliveryService';
import AddressSearch from '../Helpers/Components/AddressSearch';
import { LoadScriptNext } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

const libraries = ['places'];
function NewDelivery() {
    const [loading, setLoading] = useState(false);
    const authState = useSelector((state) => state.auth);
    const deliveryService = new DeliveryService();
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const navigate = useNavigate(); 

    // Form state
    const [externalId, setExternalId] = useState('');
    const [urgencyLevel, setUrgencyLevel] = useState('on_demand');
    const [dropoffAddress, setDropoffAddress] = useState('');
    const [dropoffLatitude, setDropoffLatitude] = useState('');
    const [dropoffLongitude, setDropoffLongitude] = useState('');
    const [dropoffName, setDropoffName] = useState('');
    const [dropoffPhoneNumber, setDropoffPhoneNumber] = useState('');
    const [deliverableAction, setDeliverableAction] = useState('meet_at_door');
    const [dropoffInstructions, setDropoffInstructions] = useState('');
    const [pickupAddress, setPickupAddress] = useState('');
    const [pickupLatitude, setPickupLatitude] = useState('');
    const [pickupLongitude, setPickupLongitude] = useState('');
    const [pickupName, setPickupName] = useState('');
    const [pickupPhoneNumber, setPickupPhoneNumber] = useState('');
    const [pickupInstructions, setPickupInstructions] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [pickupWindowStart, setPickupWindowStart] = useState('');
    const [pickupWindowEnd, setPickupWindowEnd] = useState('');
    const [dropoffWindowStart, setDropoffWindowStart] = useState('');
    const [dropoffWindowEnd, setDropoffWindowEnd] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const commonData = {
            orgID: authState.selectedOrganization.id,
            external_id: externalId,
            urgency_level: urgencyLevel,
            dropoff_address: dropoffAddress,
            dropoff_latitude: parseFloat(dropoffLatitude),
            dropoff_longitude: parseFloat(dropoffLongitude),
            dropoff_name: dropoffName,
            dropoff_phone_number: dropoffPhoneNumber,
            deliverable_action: deliverableAction,
            dropoff_instructions: dropoffInstructions || null,
            pickup_facility_id: null,
            pickup_address: pickupAddress,
            pickup_latitude: parseFloat(pickupLatitude),
            pickup_longitude: parseFloat(pickupLongitude),
            pickup_name: pickupName,
            pickup_phone_number: pickupPhoneNumber,
            pickup_instructions: pickupInstructions,
            metadata: {},
        };
        try {
            let response;
            if (urgencyLevel === 'on_demand') {
                response = await deliveryService.createOnDemandDelivery({
                    ...commonData
                });
            } else if (urgencyLevel === 'time_window') {
                response = await deliveryService.createTimeWindowDelivery({
                    ...commonData,
                    pickup_window: {
                        start: pickupWindowStart,
                        end: pickupWindowEnd,
                    },
                    dropoff_window: {
                        start: dropoffWindowStart,
                        end: dropoffWindowEnd,
                    },
                });
            } else if (urgencyLevel === 'scheduled') {
                response = await deliveryService.createScheduledDelivery({
                    ...commonData,
                    pickup_time: pickupTime,
                });
            }
            console.log('Delivery ID:', response.delivery_id);
            navigate(`/deliveries/${response.delivery_id}`);
        } catch (error) {
            console.error("Error creating delivery:", error);
            alert('Failed to create delivery.');
        } finally {
            setLoading(false);
        }
    };

    const handlePickupAddressChange = (addressDetails) => {
        setPickupAddress(addressDetails.fullAddressString);
        setPickupLatitude(addressDetails.latitude);
        setPickupLongitude(addressDetails.longitude);
    };

    const handleDropoffAddressChange = (addressDetails) => {
        setDropoffAddress(addressDetails.fullAddressString);
        setDropoffLatitude(addressDetails.latitude);
        setDropoffLongitude(addressDetails.longitude);
    };

    return (
        <LoadScriptNext googleMapsApiKey={apiKey} libraries={libraries} preventGoogleFontsLoading={true}>
        <div className="text-form">
            <h1>Create New Delivery</h1>
            <form onSubmit={handleSubmit}>
                <label className="text-form-label">
                    External ID
                    <input type="text" value={externalId} onChange={(event) => setExternalId(event.target.value)} />
                </label>
                <label className="text-form-label">
                Urgency Level
                    <select value={urgencyLevel} onChange={(event) => setUrgencyLevel(event.target.value)}
                        style={{
                            padding: '16px 10px 12px 10px',
                            fontSize: '14px',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '8px',
                            border: '0px solid #ccc',
                            outline: 'none',
                            width: '100%',
                            WebkitAppearance: 'none', // For Safari and WebKit-based browsers
                            MozAppearance: 'none', // For Firefox
                        }}>
                        <option value="on_demand">On Demand</option>
                        <option value="time_window">Time Window</option>
                        <option value="scheduled">Scheduled</option>
                    </select>
                </label>
                {urgencyLevel === 'time_window' && (<label className="text-form-label">
    Pickup Window Start
    <input 
        type="datetime-local" 
        value={pickupWindowStart} 
        onChange={(event) => setPickupWindowStart(event.target.value)} 
        className="text-input"
    />
    Pickup Window End
    <input 
        type="datetime-local" 
        value={pickupWindowEnd} 
        onChange={(event) => setPickupWindowEnd(event.target.value)} 
        className="text-input"
    />
    Dropoff Window Start
    <input 
        type="datetime-local" 
        value={dropoffWindowStart} 
        onChange={(event) => setDropoffWindowStart(event.target.value)} 
        className="text-input"
    />
    Dropoff Window End
    <input 
        type="datetime-local" 
        value={dropoffWindowEnd} 
        onChange={(event) => setDropoffWindowEnd(event.target.value)} 
        className="text-input"
    />
</label>)}
                {urgencyLevel === 'scheduled' && (<label className="text-form-label">
    Pickup Date and Time
    <input 
        type="datetime-local" 
        value={pickupTime} 
        onChange={(event) => setPickupTime(event.target.value)} 
        className="text-input"
    />
</label>)}
                <label className="text-form-label">
                Deliverable Action
                    <select value={deliverableAction} onChange={(event) => setDeliverableAction(event.target.value)}
                        style={{
                            padding: '16px 10px 12px 10px',
                            fontSize: '14px',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '8px',
                            border: '0px solid #ccc',
                            outline: 'none',
                            width: '100%',
                            WebkitAppearance: 'none', // For Safari and WebKit-based browsers
                            MozAppearance: 'none', // For Firefox
                        }}>
                        <option value="meet_at_door">Meet at door</option>
                        <option value="leave_at_door">Leave at door</option>
                    </select>
                </label>
                <label className="text-form-label">
                    Dropoff Address
                    <AddressSearch onAddressChange={handleDropoffAddressChange} inputId="dropoff_autocomplete" />
                </label>
                <label className="text-form-label">
                    Dropoff Name
                    <input type="text" value={dropoffName} onChange={(event) => setDropoffName(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Dropoff Phone Number
                    <input type="text" value={dropoffPhoneNumber} onChange={(event) => setDropoffPhoneNumber(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Dropoff Instructions
                    <input type="text" value={dropoffInstructions} onChange={(event) => setDropoffInstructions(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Pickup Address
                    <AddressSearch onAddressChange={handlePickupAddressChange} inputId="pickup_autocomplete" />
                </label>
                <label className="text-form-label">
                    Pickup Name
                    <input type="text" value={pickupName} onChange={(event) => setPickupName(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Pickup Phone Number
                    <input type="text" value={pickupPhoneNumber} onChange={(event) => setPickupPhoneNumber(event.target.value)} />
                </label>
                <label className="text-form-label">
                    Pickup Instructions
                    <input type="text" value={pickupInstructions} onChange={(event) => setPickupInstructions(event.target.value)} />
                </label>
                <input className={"primaryButton"} style={{marginTop: "40px"}} type="submit" value="Create Delivery" disabled={loading} />
            </form>
        </div>
        </LoadScriptNext>
    );
}

export default NewDelivery;
