import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeliveryService from '../../Services/deliveryService';
import { Link, useNavigate } from 'react-router-dom';

function AllDeliveries() {
    const [loading, setLoading] = useState(true);
    const [deliveries, setDeliveries] = useState([]);
    const authState = useSelector((state) => state.auth)
    const deliveryService = new DeliveryService();
    
    const fetchData = async () => {
        setLoading(true)
        try {
            const deliveryData = await deliveryService.fetchDeliveries(authState.selectedOrganization.id);
            setDeliveries(deliveryData);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching deliveries:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="billing-container">
            <div className='hStack' style={{ justifyContent: "space-between" }}>
                <h2>Deliveries</h2>
                <Link to="/newdelivery">
                <button className="add-method" >New Delivery</button> 
                </Link>
            </div>
            <div className='dividerHorizontal' style={{ margin: "0px 0px 5px 0px" }}></div>
            {loading ? (
                <div style={{display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"}}>
                <div className='loading-indicator'></div>
                </div>
            ) : (
                <div>
            {deliveries.length > 0 ? deliveries.map(delivery => (
                <Link to={`/deliveries/${delivery.id}`} key={delivery.id} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div style={{ padding: "3px 0px" }}>
                    <div className="hStack" style={{ justifyContent: "space-between", padding: "10px 12px 10px 12px", background: "#f1f1f1", borderRadius: "8px" }}>
                        <div className="vStack" style={{ justifyContent: "space-between" }}>
                            <div className='hStack'>
                                <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{delivery.external_id} {delivery.dropoff_name}</span>
                            </div>
                            <p style={{ fontSize: "small", margin: "0px", padding: "0px" }}>{delivery.pickup_time}</p>
                        </div>
                        <div className='hStack' style={{ gap: "5px" }}>
                            <p style={{ fontSize: "10px", fontWeight: "550", color: "var(--accent-color)", margin: "0px 0px 0px 5px", padding: "2px 4px", backgroundColor: "rgba(88, 180, 237, 0.2)", borderRadius: "4px" }}>{delivery.status}</p>
                        </div>
                    </div>
                </div>
            </Link>
            )) : null}
            </div> )}
        </div>
    );
}

export default AllDeliveries;
