import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Helpers/Contexts/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../Services/authService';

const CodeVerificationScreen = () => {
    const [verificationCode, setVerificationCode] = useState(Array(6).fill(''));
    const { confirmationResult } = useContext(AuthContext)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const inputsRef = useRef([]);
    const state = useSelector(state => state.auth)

    const authService = new AuthService();

    useEffect(() => {
        if (confirmationResult == null) {
            navigate("/login")
        }
    }, [confirmationResult])

    const handleSubmit = async () => {
        const verificationString = verificationCode.join('');
        await authService.signInWithCode(verificationString, confirmationResult, dispatch)
            .then((result) => {
                if (state.hasProfile) {
                    navigate("/")
                }else{
                    navigate("/createprofile")
                }
            }).catch((error) => {
                console.log("signwithcode", error);
            }).finally(() => {
                console.log("finally");
            })
    }

    useEffect(() => {
        // Check if all elements of verificationCode are filled
        const isCodeComplete = verificationCode.length === 6 && verificationCode.every(digit => digit.trim().length === 1);

    if (isCodeComplete) {
        handleSubmit();
    }
    }, [verificationCode]);

    const focusNextInput = (index) => {
        if (index < 5) {
            inputsRef.current[index + 1].focus();
        } else if (index === 5) {
            inputsRef.current[index].blur();
        }
    };

    const focusPreviousInput = (index) => {
        if (index > 0) {
            inputsRef.current[index - 1].focus();
        }
    };

    useEffect(() => {
        inputsRef.current[0].focus();
    }, []);

    const handleBackspace = (index) => {
        const newCode = [...verificationCode];
        if (newCode[index] === '') {
            newCode[index - 1] = '';
            setVerificationCode(newCode);
            focusPreviousInput(index);
        }
    };

    const handleInputChange = (value, index) => {
        const newCode = [...verificationCode];
        newCode[index] = value;

        if (value) {
            focusNextInput(index);
        }

        setVerificationCode(newCode);
    };

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div style={styles.container}>
            <span onClick={goBack} className="material-symbols-rounded" style={{ display: "block", textAlign: "left", margin: "0", padding: "0", fontSize: "30px" }}>
                chevron_left
            </span>
            <h2 style={{ fontWeight: "400" }}>Enter the 6 digit code sent to you</h2>
            <div className="verification-container" style={styles.inputGroup}>
                {verificationCode.map((digit, index) => (
                    <input
                        key={index}
                        ref={(ref) => (inputsRef.current[index] = ref)}
                        type="text"
                        maxLength="1"
                        value={digit}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onChange={(e) => handleInputChange(e.target.value, index)}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace') {
                                handleBackspace(index);
                            }
                        }}
                    />
                ))}
                <div style={{ flexGrow: "1" }}></div>
            </div>
            <button onClick={handleSubmit} style={styles.continueButton}>Continue</button>
        </div>
    );
}

const styles = {
    container: {
        width: '90%',
        maxWidth: '360px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#fff',
        overflow: "hidden",
    },
    disclaimer: {
        fontSize: '12px',
        fontWeight: 'normal',
        textAlign: 'left',
        color: 'rgb(161, 161, 161)'
    },
    label: {
        display: 'block',
        marginBottom: '10px',
        fontSize: '16px',
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    countryCode: {
        marginRight: '10px',
        backgroundColor: '#f0f0f0',
        padding: '12px 10px',
        borderRadius: '8px',
        border: '0px solid #ccc',
    },
    continueButton: {
        width: '100%',
        padding: '15px',
        fontSize: '16px',
        fontWeight: '550',
        backgroundColor: 'var(--accent-color)',
        color: '#fff',
        border: 'none',
        margin: "60px 0px 0px 0px",
        borderRadius: '8px',
        cursor: 'pointer',
    },
    resendButton: {
        padding: '8px 18px',
        fontSize: '16px',
        margin: "0px 0px 60px 0px",
        fontWeight: '500',
        backgroundColor: '#f1f1f1',
        color: '#000',
        border: 'none',
        borderRadius: '25px',
        cursor: 'pointer',

    }
}

export default CodeVerificationScreen;
