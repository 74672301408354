import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeliveryService from '../../Services/deliveryService';
import { useNavigate, useParams } from 'react-router-dom';
import FormSection from '../../Helpers/Components/FormSection';
import formatIsoDateTime from '../../Helpers/Functions/FormatIsoDateTime';
import MapViewTwoMarkers from '../../Helpers/Components/MapViewTwoMarkers';

function DeliveryDetailView() {
    const { deliveryId } = useParams();
    const [loading, setLoading] = useState(true);
    const [delivery, setDelivery] = useState([]);
    const authState = useSelector((state) => state.auth)
    const deliveryService = new DeliveryService();
    const navigate = useNavigate();
    const { readableDate, readableTime } = formatIsoDateTime(delivery.pickup_time);
    
    const fetchData = async () => {
        setLoading(true)
        try {
            const deliveryData = await deliveryService.fetchDeliveryById(authState.selectedOrganization.id, deliveryId);
            setDelivery(deliveryData);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching deliveries:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const cancelDelivery = async () => {
        try {
            const response = await deliveryService.cancelDelivery(authState.selectedOrganization.id, deliveryId);
            if (response.success) { // Assuming the service returns a success indicator
                navigate("/deliveries");
            } else {
                console.error("Cancellation was not successful");
            }
        } catch (error) {
            console.error("Error cancelling delivery:", error);
        }
    };

    return (
        <div className='formBackground'>
        <div className="billing-container">
                <div>
                <FormSection title="SUMMARY" fields={[
        { label: "External ID", value: delivery.external_id },
        { label: "ID", value: delivery.id },
        { label: "Type", value: delivery.urgency_level },
        { label: "Status", value: delivery.status },
        { label: "Payment Status", value: delivery.payment_status },
        { label: "Date", value: readableDate },
        { label: "Time", value: readableTime },
    ]} />
    <FormSection title="PICKUP DETAILS" fields={[
        { label: "Name", value: delivery.pickup_name },
        { label: "Phone Number", value: delivery.pickup_phone_number },
        { label: "Instructions", value: delivery.pickup_instructions },
    ]} />
    <FormSection title="DROPOFF DETAILS" fields={[
        { label: "Name", value: delivery.dropoff_name },
        { label: "Phone Number", value: delivery.dropoff_phone_number },
        { label: "Instructions", value: delivery.dropoff_instructions },
    ]} />
    {!loading && (
                        <MapViewTwoMarkers 
                        key={delivery.id}
                            location1={{
                                lat: delivery.pickup_latitude,
                                lng: delivery.pickup_longitude
                            }}
                            location2={{
                                lat: delivery.dropoff_latitude,
                                lng: delivery.dropoff_longitude
                            }}
                            name1={`${delivery.pickup_name}`}
                            name2={`${delivery.dropoff_name}`} 
                        />
                    )}
                    <div className="addressTitle">Pickup From</div>
                    <p className='addressText'>{delivery.pickup_address}</p>
                    <div className="addressTitle">Delivering to</div>
                    <p className='addressText' >{delivery.dropoff_address}</p>
                    <button 
                    className="cancelButton" 
                    style={{margin: "0px 20px"}}
                    onClick={cancelDelivery}>
                        Cancel Delivery</button>
            </div>
        </div>
        </div>
    );
}

export default DeliveryDetailView;