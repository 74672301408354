import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';

export default function MapView({ lat, lng, height }) {
  const containerStyle = {
    margin: '0px 0px',
    boxSizing: 'content-box',
    height: `${height}`,
    borderRadius: '15px',
    transition: "height 0.3s ease",
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    scrollwheel: false,
    draggable: false,
    disableDoubleClickZoom: true,
};
  
  const center = {
    lat: lat,
    lng: lng
  };

  return (
    <GoogleMap
    mapContainerStyle={containerStyle}
    center={center}
    options={mapOptions}
    zoom={16}
    >
    <Marker position={center} />
    </GoogleMap>
  );
}
