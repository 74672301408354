import { SET_USER, SET_SIGN_IN_STATE, SET_SELECTED_INVITE, SET_SELECTED_ORGANIZATION } from "../Actions/authActions";

const initialState = {
    isSignedIn: false,
    uid: null,
    hasProfile: false,
    user: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload.user,
                hasProfile: action.payload.hasProfile,
            };
        case SET_SIGN_IN_STATE:
            return {
                ...state,
                isSignedIn: action.payload.signedIn,
                uid: action.payload.uid,
            };
        case SET_SELECTED_INVITE:
            return {
                ...state,
                selectedInvite: action.payload,
            };
        case SET_SELECTED_ORGANIZATION:
            return {
                ...state,
                selectedOrganization: action.payload,
            }
        default:
             return state
    }
};

export default authReducer