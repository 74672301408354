import React from 'react';
import '../../Styles/Helpers/Popup.css'

const Popup = ({ show, children, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
