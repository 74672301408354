import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import RestaurantService from '../../Services/restaurantService';

function RestaurantsMenuMain() {
    const [loading, setLoading] = useState(true);
    const { restaurantId } = useParams();
    const [menu, setMenu] = useState([]);
    const authState = useSelector((state) => state.auth)
    const restService = new RestaurantService();
    
    const fetchData = async () => {
        setLoading(true)
        try {
            const menuData = await restService.fetchRestaurantMenu(restaurantId);
            setMenu(menuData);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching deliveries:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="billing-container">
            <div className='hStack' style={{ justifyContent: "space-between" }}>
                <h2>Menu</h2>
                <Link to="/newmenuitem">
                <button className="add-method" >Add Item</button> 
                </Link>
            </div>
            <div className='dividerHorizontal' style={{ margin: "0px 0px 5px 0px" }}></div>
            {loading ? (
                <div style={{display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"}}>
                <div className='loading-indicator'></div>
                </div>
            ) : (
                <div>
            {menu.length > 0 ? menu.map(item => (
                <Link to={`/restaurants/${restaurantId}/menu/${item.id}`} key={item.id} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div style={{ padding: "3px 0px" }}>
                    <div className="hStack" style={{ justifyContent: "space-between", padding: "10px 12px 10px 12px", background: "#f1f1f1", borderRadius: "8px" }}>
                        <div className="vStack" style={{ justifyContent: "space-between" }}>
                                <span style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>{item.name}</span>
                        </div>
                    </div>
                </div>
            </Link>
            )) : null}
            </div> )}
        </div>
    );
}

export default RestaurantsMenuMain;