import { signInWithPhoneNumber, signOut } from "firebase/auth";
import { addDoc, collection, collectionGroup, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { authInstance, firestoreInstance, functionsInstance } from "..";
import { httpsCallable } from "firebase/functions"
import { setSelectedInvite, setSelectedOrganization, setSignInState, setUser } from "../Redux/Actions/authActions";
import axios from 'axios';


const ERROR_MESSAGES = {
    "auth/invalid-phone-number": "The phone number format is incorrect. Please check and try again.",
    "auth/missing-phone-number": "Please provide your phone number.",
    "auth/quota-exceeded": "We've sent too many codes. Please try again later.",
    "auth/user-disabled": "This user has been disabled. Please contact support.",
    "auth/operation-not-allowed": "Phone verification is not available right now. Please contact support.",
    "default": "An error occurred. Please try again."
};

class AuthService {

    async createSetupIntent(orgID) {
        try {
            const response = await axios.post("https://createsetupintent-i4bl6pegoq-ew.a.run.app", { orgID });
        
            return {
              clientSecret: response.data.setupIntent,
              customerID: response.data.customerID,
            };
          } catch (error) {
            console.error("Error getting intent:", error);
            // Consider throwing a specific error or returning an error response here
            throw new Error("Failed to create Setup Intent. Please try again later.");
          }
    }

    async fetchMethods(orgID) {
        try {
            const response = await axios.post("https://fetchpaymentmethods-i4bl6pegoq-ew.a.run.app", { orgID });
        
            return response.data;
          } catch (error) {
            console.error("Error getting intent:", error);
            // Consider throwing a specific error or returning an error response here
            throw new Error("Failed to create Setup Intent. Please try again later.");
          }
    }
    
        async sendVerificationCode(number, recaptchaVerifier, dispatch) {
                    const confirmationResult = await signInWithPhoneNumber(authInstance, number, recaptchaVerifier)
                    .catch((error) => {
                        const errorMessage = ERROR_MESSAGES[error.code] || ERROR_MESSAGES["default"];
                        return Promise.reject(new Error(errorMessage));
                    });
                    dispatch(setUser({phoneNumber: number}, false))
                        console.log(confirmationResult);
                        return confirmationResult
        }
        
        
    
        async signInWithCode(code, confirmationResult, dispatch) {
            try {
                console.log(confirmationResult);
                const result = await confirmationResult.confirm(code);
                this.fetchUserProfile(result.user.uid, dispatch)
                return "Success";
            } catch (error) {
                console.log(error)
                throw error;
            }
        }

        async createProfile(uid, name, email, number, dispatch) {
            try {
                const userDoc = doc(firestoreInstance, "Users/"+uid)
                const userData = {
                    id: uid,
                    fullName: name,
                    email: email,
                    phoneNumber: number,
                    dateJoined: serverTimestamp(),
                }
                await setDoc(userDoc, userData)
                this.fetchUserProfile(uid, dispatch)
                return "Success";
            } catch (error) {
                console.log(error)
                throw error;
            }
        }

        async createOrganization(name, email, number, website, billingAddress, user, uid) {
            try {
                const coll = collection(firestoreInstance, "Organizations")
                const orgData = {
                    name: name,
                    supportEmail: email,
                    supportPhoneNumber: number,
                    website: website,
                    billingAddress: billingAddress,
                    adminSet: false,
                }
                const orgDoc = await addDoc(coll, orgData)
                const orgID = orgDoc.id;
                const adminDoc = doc(firestoreInstance, "Organizations/"+orgID+"/Team/"+uid)
                const adminData = {
                    fullName: user.fullName,
                    phoneNumber: user.phoneNumber,
                    email: user.email,
                    id: uid,
                    role: "Admin"
                }
                await setDoc(adminDoc, adminData)
                await updateDoc(orgDoc, {adminSet: true, id: orgID})
                return "Success";
            } catch (error) {
                console.log(error)
                throw error;
            }
        }

        async joinOrganization(user, orgID, inviteID) {
            try {
                const acceptTeamInvite = httpsCallable(functionsInstance, 'AcceptTeamInvite');
                await acceptTeamInvite({orgID: orgID, inviteID: inviteID, userData: user});
                return "Success";
            } catch (error) {
                console.log(error)
                throw error;
            }
        }

        async fetchUserOrganization(uid, dispatch) {
            try {
                // Query the Team collection group for the user's document 
                const teamquery = query(collectionGroup(firestoreInstance, "Team"), where("id", "==", uid));
                const querySnapshot = await getDocs(teamquery);
                if (!querySnapshot.empty) {
                    // Assuming the user is only part of one team
                    const userTeamDoc = querySnapshot.docs[0];
                    const orgID = userTeamDoc.ref.parent.parent.id;
                    // Fetch the organization data
                    const orgSnapshot = await getDoc(doc(firestoreInstance, `Organizations/${orgID}`));
                    if (orgSnapshot.exists()) {
                        const orgData = orgSnapshot.data();
                        const role = userTeamDoc.data().role;
                        const combinedData = {
                            ...orgData, // Include all fields from orgData
                            role: role, // Add the 'role' field
                          };
                        // Merge user data with organization data and dispatch
                        dispatch(setSelectedOrganization(combinedData));
                    } else {
                        console.log("Organization not found for the team");
                        // Handle organization not found
                    }
                } else {
                    dispatch(setSelectedOrganization(null))
                    console.log("User is not part of any team");
                    // Handle case where the user is not part of any team
                }
            } catch (error) {
                console.log("Error fetching user organization:", error);
                throw error;
            }
        }
        

        async fetchUserProfile(uid, dispatch) {
            try {
                dispatch(setSignInState(true, uid))
                const snapshot = await getDoc(doc(firestoreInstance, `Users/${uid}`));
                if (snapshot.exists()) {
                    this.fetchUserOrganization(uid, dispatch)
                    const { dateJoined, ...otherData } = snapshot.data();
                    const userData = otherData;
                    dispatch(setUser(userData, true));
                }else{
                    const number = authInstance.currentUser.phoneNumber;
                    console.log("Not logged in:", number)
                    dispatch(setUser({phoneNumber: number}, false))
                }
            } catch (error) {
                console.log(error)
                throw error;
            }
        }

        async checkOrganizationInvites(phoneNumber) {
            try {
                const querySnapshot = await getDocs(query(collection(firestoreInstance, "OrganizationInvitations"), where("phoneNumber", "==", phoneNumber)));
                if (!querySnapshot.empty) {
                    const invitations = [];
                    querySnapshot.forEach((docSnapshot) => {
                        const invitationData = { id: docSnapshot.id, ...docSnapshot.data() };
                        invitations.push(invitationData);
                    });
                    return invitations;
                } else {
                   const teamSnapshot = await getDocs(query(collectionGroup(firestoreInstance, "TeamInvites"), where("phoneNumber", "==", phoneNumber)));
                  //  const teamSnapshot = await getDoc(doc(firestoreInstance, "Organizations/Bq8E2W4SK1IS8upX51Uy/TeamInvites/1bb6i7wUL3x5mKIMZZoz"));
                if (!teamSnapshot.empty) {
                    const invitations = [];
                    teamSnapshot.forEach((docSnapshot) => {
                       const invitationData = { id: docSnapshot.id, ...docSnapshot.data() };
                       invitations.push(invitationData);
                   });
                    return invitations;
                }else{
                    console.log("No invitations found for this phone number");
                    return [];
                }
            }
            } catch (error) {
                console.log(error)
                throw error;
            }
        }
    
        async logout(dispatch) {
            try {
                await signOut(authInstance);
                dispatch(setUser(null, false));
                dispatch(setSignInState(false, null))
                dispatch(setSelectedOrganization(null))
                dispatch(setSelectedInvite(null))
                return true;  // Successfully logged out
            } catch (error) {
                console.log(error)
                throw error;
            }
        }
    }
    
    export default AuthService;