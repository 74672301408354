import React, { useState } from 'react';
import StepperCounter from '../Helpers/Components/StepperCounter';
import OrgService from '../../Services/orgService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Popup from '../Helpers/Popup';

function NewCampaign() {
  const [bikes, setBikes] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [confirmDeposit, setConfirmDeposit] = useState(false);
  const authState = useSelector((state) => state.auth)
  const navigate = useNavigate()
    const orgService = new OrgService();

    

  const handleCreate = async () => {
    try {
        const selectedPackage = plans[selectedPlan];
        await orgService.createAdvertisingCampaign(authState.selectedOrganization.id, bikes, selectedPackage.name, selectedPackage.activationFee, selectedPackage.monthlyFee, authState.uid)
        navigate("/advertising")
    } catch (error) {
        console.error("Error fetching team data:", error);
    }
  }

  const handleDecrement = () => {
    if (bikes !== 1) {
      setBikes(bikes-1)
    }
  }
  const formatNumber = (number) => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0, // Adjust if needed
      maximumFractionDigits: 0, // Adjust if needed
    });
  };
  const handleIncrement = () => {
    if (bikes < 24) {
      setBikes(bikes+1)
    }
  }

  const plans = [
    {
      name: '12 Months',
      activationFee: 1200,
      monthlyFee: 500,
      totalCost: 7200,
    },
    {
      name: '12 Months',
      activationFee: 0,
      monthlyFee: 750,
      totalCost: 9000,
    },
    {
      name: 'Month-to-Month',
      activationFee: 1200,
      monthlyFee: 750,
      totalCost: 10200,
    },
  ];

  const handlePlanChange = (index) => {
    setSelectedPlan(index);
  };

  const calculateTotalCost = () => {
    const selectedPackage = plans[selectedPlan];
    const totalActivationFee = selectedPackage.activationFee * bikes;
    const monthlyCost = selectedPackage.monthlyFee * bikes;
    return totalActivationFee + (monthlyCost * 12);
  };

  const calculateActivationFee = () => {
    const selectedPackage = plans[selectedPlan];
    const totalActivationFee = selectedPackage.activationFee * bikes;
    return totalActivationFee
  };

  const calculateDistance = () => {
    const selectedPackage = plans[selectedPlan];
    const distance = 1750 * bikes;
    return distance
  };

  const calculateImpressions = () => {
    const selectedPackage = plans[selectedPlan];
    const impressions = 275000 * bikes;
    return impressions
  };

  const calculateMonthlyCost = () => {
    const selectedPackage = plans[selectedPlan];
    const monthlyCost = selectedPackage.monthlyFee * bikes;
    return monthlyCost;
  };

  const handlePay = async () => {
    try {
      await orgService.payDeposit(calculateActivationFee(), authState.selectedOrganization.id)
      const selectedPackage = plans[selectedPlan];
      await orgService.createAdvertisingCampaign(authState.selectedOrganization.id, bikes, selectedPackage.name, selectedPackage.activationFee, selectedPackage.monthlyFee, authState.uid)
      navigate("/advertising")
  } catch (error) {
      console.error("Error fetching team data:", error);
  }
  }

  return (
    <div className="billing-container">
      <h2>Create Campaign</h2>
      <div>
      <div className="advertising-options-container">
      {plans.map((plan, index) => (
              <div className={selectedPlan === index ? 'advertising-option-active' : 'advertising-option'} key={index} onClick={() => handlePlanChange(index)}>
    <div className="advertising-option__title">{plan.name}</div>
    <div className="advertising-option__activation">{plan.activationFee === 0 ? "No Activation Fee" : `R${plan.activationFee} Activation Fee`}</div>
    <div className="advertising-option__price">R{plan.monthlyFee}</div>
    <div className="advertising-option__month">/month</div>
    <div className="advertising-option__text">{`R${formatNumber(plan.totalCost)} Total Cost ${plan.name === "Month-to-Month" ? "(12 months)" : ""}`}</div>
  </div>
          ))}
</div>
      </div>
        <div className='advertising-summary hStack'>
          <div className='advertising-summary-infoblock'>
        <div className="advertising-option__price">{bikes}</div>
    <div className="advertising-option__month">{bikes === 1 ? "Bike" : "Bikes"}</div>
    <StepperCounter onIncrement={handleIncrement} onDecrement={handleDecrement} value={bikes} showBin={false} style={{backgroundColor: "white"}} />
    </div>
    <div className='advertising-summary-infoblock'>
        <div className="advertising-option__price">{formatNumber(calculateDistance())} km</div>
    <div className="advertising-option__month">driven/month</div>
    </div>
    <div className='advertising-summary-infoblock'>
        <div className="advertising-option__price">{formatNumber(calculateImpressions())}</div>
    <div className="advertising-option__month">Impressions/month</div>
    </div>
    <div className='advertising-summary-infoblock'>
        <div className="advertising-option__price">R{formatNumber(calculateActivationFee())}</div>
    <div className="advertising-option__month">Total Activation Fees</div>
    </div>
    <div className='advertising-summary-infoblock'>
        <div className="advertising-option__price">R{formatNumber(calculateMonthlyCost())}</div>
    <div className="advertising-option__month">Monthly Cost</div>
    </div>
    <div className='advertising-summary-infoblock'>
        <div className="advertising-option__price">R{formatNumber(calculateTotalCost())}</div>
    <div className="advertising-option__month">Total Cost</div>
    </div>
  </div>
        <button className="primaryButton" onClick={() => {setConfirmDeposit(true)}}>Create Campaign</button>
        <Popup show={confirmDeposit} onClose={() => {setConfirmDeposit(false)}}>
        <h4>Are you sure you would like to create this campaign, you will be charged R{calculateActivationFee()/2}</h4>
        <button className="popup-close-button" onClick={() => {setConfirmDeposit(false)}}>Cancel</button>
        <button className="popup-action-button" onClick={handlePay}>Confrim</button>
            </Popup>
    </div>
    
  );
}

export default NewCampaign;
